import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../zz_general/utils/configs/firebase';
import { consoleLog } from '../../../zz_general/utils/snippets/console';
import { isProd } from '../../../zz_general/utils/snippets/env';
import { BRv2_lark_postMassage } from '../../../zz_general/utils/functions';

export const fetchCode = async (code) => {
  try {
    const docRef = doc(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'codes', code);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      consoleLog('Code does not exist in FireStore');
      return {
        error: 'コードが誤っています。',
      };
    }
    if (docSnap.data().used) {
      consoleLog('Code has already been used');
      return {
        error: 'すでに使用したコードです。',
      };
    }
    consoleLog('code:', docSnap.data());
    return {
      error: '',
      num: docSnap.data().num,
      inflow: docSnap.data().inflow,
    };
  } catch (error) {
    consoleLog('Error fetching code data: ', error);
    return {
      error: '予期せぬエラーが発生しました。お手数ですが、時間をおいて再度お試しください。',
    };
  }
};

export const useCode = async (code) => {
  try {
    const docRef = doc(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'codes', code);
    await updateDoc(docRef, {
      used: true,
    });
  } catch (error) {
    consoleLog('Error fetching code data: ', error);
    await BRv2_lark_postMassage(
      'quiz_error',
      `ユーザーが挑戦権付与コードを使用後、DB更新時にエラーが発生しました。\n\nコード: ${code}\n\nエラー内容: ${error}`
    );
  }
};
