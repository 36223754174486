import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;

  h2 {
    color: #333;
    margin-bottom: 20px; // Add margin for spacing
  }

  table {
    width: 100%; // Full width
    border-collapse: collapse; // Collapse borders
    margin-top: 20px; // Space above the table

    th,
    td {
      border: 1px solid #ddd; // Border for table cells
      padding: 6px; // Further reduced padding for table cells
      text-align: left; // Align text to the left
      font-size: 12px; // Further reduced font size for table content
    }

    th {
      background-color: #f4f4f4; // Light gray background for headers
      color: #333; // Header text color
      font-size: 14px; // Reduced font size for headers
    }

    // Adjust column widths
    td:nth-child(4),
    th:nth-child(4) {
      // Email Address
      width: 15%; // Reduced width for Email Address
    }
    td:nth-child(3), th:nth-child(3),
    td:nth-child(5), th:nth-child(5), // Total Amount Sales
    td:nth-child(6), th:nth-child(6), // Sales Operation
    td:nth-child(7), th:nth-child(7) {
      // Operation
      width: 20%; // Increased width for Sales Operation and Operation
    }

    tr:hover {
      background-color: #f1f1f1; // Change background on hover
    }
  }

  .cancel-button {
    color: red; // Red text color
    background-color: transparent; // No background color
    border: none; // Remove default button border
    cursor: pointer; // Pointer cursor on hover
    font-weight: bold; // Bold text
    font-size: 12px; // Reduced font size for the button

    &:hover {
      text-decoration: underline; // Underline on hover for better UX
    }
    &:disabled {
      color: #888; // Gray text color for disabled state
      cursor: not-allowed; // Not-allowed cursor for disabled state
      text-decoration: none; // Remove underline for disabled state
      opacity: 0.6; // Lower opacity for disabled appearance
    }
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center all the content */

    .refund-amount-row {
      display: flex;
      align-items: center;
      margin-top: 10px;

      label {
        margin-right: 10px;
        font-weight: bold;
      }

      span {
        margin-right: 5px;
        font-weight: bold;
      }

      input {
        flex: 1;
        padding: 8px;
      }
    }

    .checkbox-row {
      display: flex;
      align-items: center; /* Vertically center align checkbox and label */
      justify-content: center; /* Center horizontally if needed */
      margin-top: 15px;

      input[type='checkbox'] {
        margin-right: 10px; /* Space between checkbox and label */
        margin-bottom: 0px;
        width: auto; /* Ensure checkbox does not stretch */
        height: 20px; /* Set consistent height for the checkbox */
      }

      label {
        font-weight: bold;
        margin-bottom: 7px;
        font-size: 14px;
        color: #333; /* Text color */
        line-height: 1; /* Ensure text aligns perfectly with the checkbox */
      }
    }
  }

  .modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    button:first-child {
      background-color: #007bff;
      color: white;
    }

    button:last-child {
      background-color: #f44336;
      color: white;
    }
  }

  label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }

  input {
    margin-top: 5px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
  }
`;
