import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { loadStripe } from '@stripe/stripe-js';
import { StyledDiv } from '../../utils/styled';

import css from './index.module.scss';

import { importAll } from '../../utils/image';

import { LinkButton } from '../../components/linkButton';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  addKamaitachiCustomerState,
  addKamaitachiPaymentState,
  addKamaitachiReservationIdState,
  addKamaitachiTotalState,
} from '../../store/atoms';
import { FormHeader } from '../../components/fourStepBar';
import { BRv2_paypay_createPaymentLink, BRv2_stripe_createPaymentIntent } from '../../../zz_general/utils/functions';

export const KamaitachiPaymentTakeout = () => {
  const total = useRecoilValue(addKamaitachiTotalState);
  const customer = useRecoilValue(addKamaitachiCustomerState);
  const reservationId = useRecoilValue(addKamaitachiReservationIdState);

  // MEMO: Stripe決済に必要な情報
  const stripePayload = {
    payload: {
      amount: total.totalPrice,
      currency: 'jpy',
      receipt_email: customer.mailAddress,
      automatic_payment_methods: {
        enabled: true,
      },
      metadata: { reservationsId: reservationId },
    },
  };

  // MEMO: PayPay決済に必要な情報
  const payPayPayload = {
    merchantPaymentId: reservationId,
    amount: total.totalPrice,
    redirectUrl:
      process.env.REACT_APP_IS_PROD === 'true'
        ? `https://bene-regalo.com/tachibana-ran-collabo/takeout/customer-page?reservationsId=${reservationId}`
        : `http://localhost:3000/tachibana-ran-collabo/takeout/customer-page?reservationsId=${reservationId}`,
  };

  const navigate = useNavigate();
  const inputFormWidth = [340, 512, 720];
  const linkButtonHeight = [48, 52, 60];
  const image = importAll(require.context('./image', false, /\.(webp|avif)$/));
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [payment, setPayment] = useRecoilState(addKamaitachiPaymentState);
  // MEMO: RecoilStateの決済手段を更新
  const setPaymentMode = (value) => {
    setPayment((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['mode'] = value;
      return future;
    });
  };
  // MEMO: RecoilStateの決済情報を更新
  const setPayPayDataByProp = (key, value) => {
    setPayment((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['payPay'][key] = value;
      return future;
    });
  };
  // MEMO: RecoilStateの決済情報を更新
  const setStripeDataByProp = (key, value) => {
    setPayment((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['stripe'][key] = value;
      return future;
    });
  };
  const stripePromise =
    process.env.REACT_APP_IS_PROD === 'true'
      ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY.toString())
      : loadStripe(process.env.REACT_APP_STRIPE_TEST_API_KEY.toString());

  // MEMO: PayPay決済に必要な遷移リンクを取得
  // MEMO: PayPay決済に必要な遷移リンクを取得
  const fetchPaymentLink = async () => {
    try {
      const data = await BRv2_paypay_createPaymentLink(payPayPayload);
      setPayPayDataByProp('paymentLink', data.url);
      setPayPayDataByProp('deepLink', data.deeplink);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error generating QR code:', error);
    }
  };

  // MEMO: Stripe決済に必要なPaymentIntentを取得
  const fetchPaymentIntent = async () => {
    try {
      const data = await BRv2_stripe_createPaymentIntent(stripePayload);
      if (data) {
        setStripeDataByProp('paymentIntent', data);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching payment intent:', error);
    }
  };

  useEffect(() => {
    // MEMO : 直接アクセスされた場合のリダイレクト
    if (total.totalCount === 0) {
      navigate('/tachibana-ran-collabo');
    }
    fetchPaymentIntent();
    fetchPaymentLink();
  }, []);

  return (
    <StyledDiv
      className={classNames(css.payment)}
      responsive={{
        width: inputFormWidth,
      }}
    >
      <FormHeader step={2} title="決済" />
      <div className={classNames(css.title)}>決済金額</div>
      <div className={classNames(css.totalPrice)}>
        <span className={classNames(css.total)}>合計</span>
        <span className={classNames(css.yen)}>￥</span>
        <span className={classNames(css.price)}>{total.totalPrice.toLocaleString()}</span>
        <span className={classNames(css.taxIncluded)}>(税込)</span>
      </div>
      {/* MEMO : PayPay決済はひとまず非表示 */}
      <div className={classNames(css.title)}>支払方法</div>
      <div
        className={classNames(css.card, payment.mode === 'stripe' ? css.cardActive : css.cardPassive)}
        onClick={() => {
          setPaymentMode('stripe');
        }}
      >
        クレジットカード
        {payment.mode === 'stripe' && <img src={image['check.webp']} className={classNames(css.check)} />}
      </div>
      <div
        className={classNames(css.payPay, payment.mode === 'payPay' ? css.payPayActive : css.payPayPassive)}
        onClick={() => {
          setPaymentMode('payPay');
        }}
      >
        <img src={image['pay_pay.webp']} className={classNames(css.payPayLogo)} />
        {payment.mode === 'payPay' && <img src={image['check.webp']} className={classNames(css.check)} />}
      </div>
      <div className={classNames(payment.mode === 'stripe' ? css.displayActive : css.displayPassive)}>
        <Elements stripe={stripePromise}>
          <StripeForm
            setStripeDataByProp={setStripeDataByProp}
            image={image}
            navigate={navigate}
            inputFormWidth={inputFormWidth}
            linkButtonHeight={linkButtonHeight}
          />
        </Elements>
      </div>
      <div className={classNames(payment.mode === 'payPay' ? css.displayActive : css.displayPassive)}>
        <LinkButton
          className={classNames(css.linkButton)}
          width={inputFormWidth}
          height={linkButtonHeight}
          text="決済へ進む"
          color="black"
          active={true}
          loading={buttonIsLoading}
          onClick={() => {
            setButtonIsLoading(true);
            navigate('/tachibana-ran-collabo/takeout/confirm');
          }}
        />
        <LinkButton
          width={inputFormWidth}
          height={linkButtonHeight}
          text="お客様情報に戻る"
          color="white"
          arrow="left"
          active={true}
          onClick={() => {
            navigate('/tachibana-ran-collabo/takeout/customer-info');
          }}
        />
      </div>
    </StyledDiv>
  );
};

const StripeForm = ({ setStripeDataByProp, image, navigate, inputFormWidth, linkButtonHeight }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [fontSize, setFontSize] = useState(16);
  const [brand, setBrand] = useState('');
  const [showHelp, setShowHelp] = useState(false);
  const [formFocused, setFormFocused] = useState('');
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isFormEmpty, setIsFormEmpty] = useState({
    cardNum: true,
    holdersName: true,
    cardExpiry: true,
    cvc: true,
  });
  const setIsFormEmptyByProp = (key, value) => {
    setIsFormEmpty((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  // MEMO: Stripe決済に必要なPaymentMethodを取得
  const fetchPaymentMethod = async () => {
    setButtonIsLoading(true);
    if (!stripe || !elements) return;

    // MEmo: カード情報の取得、cardNumberElementが名義や有効期限等の情報も持っている
    const card = elements.getElement(CardNumberElement);
    if (card == null) return;

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: 'name',
      },
    });
    if (payload.error) {
      // eslint-disable-next-line no-console
      console.log(payload.error.message);
      setError(payload.error.message);
      setTimeout(() => {
        setButtonIsLoading(false);
      }, 2000);
    } else {
      // eslint-disable-next-line no-console
      console.log(payload.paymentMethod);
      setStripeDataByProp('paymentMethod', payload.paymentMethod);
      navigate('/tachibana-ran-collabo/takeout/confirm');
    }
  };

  useEffect(() => {
    if (
      Object.keys(isFormEmpty).every(function (key) {
        return !isFormEmpty[key];
      })
    ) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  }, [isFormEmpty]);

  useEffect(() => {
    const updateFontSize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 375) {
        setFontSize((16 * screenWidth) / 375);
      } else if (screenWidth < 834) {
        setFontSize(0.017429193899782 * screenWidth + 9.4640522875817);
      } else if (screenWidth < 1440) {
        setFontSize(0.013201320132013 * screenWidth + 12.990099009901);
      } else {
        setFontSize((32 * screenWidth) / 1440);
      }
    };

    updateFontSize();
    window.addEventListener('resize', updateFontSize);

    return () => {
      window.removeEventListener('resize', updateFontSize);
    };
  }, []);
  return (
    <>
      <div className={classNames(css.cardIconWrap)}>
        <img src={image['visa.webp']} className={classNames(css.cardIcon)} />
        <img src={image['mastercard.webp']} className={classNames(css.cardIcon)} />
        <img src={image['jcb.webp']} className={classNames(css.cardIcon)} />
        <img src={image['amex.webp']} className={classNames(css.cardIcon)} />
        <img src={image['diners.webp']} className={classNames(css.cardIcon)} />
      </div>
      <div className={classNames(css.cardInputForm)}>
        <div className={classNames(css.face)}>
          <div className={classNames(css.imgWrap)}>
            <img src={image['ic.webp']} className={classNames(css.ic)} />
            <img src={image[`${brand}_logo.webp`]} className={classNames(css[`${brand}`])} />
          </div>
          <div className={classNames(css.inputLabel)}>カード番号</div>
          <CardNumberElement
            onChange={(e) => {
              setBrand(e.brand);
              setIsFormEmptyByProp('cardNum', e.empty);
            }}
            onFocus={() => {
              setFormFocused('cardNum');
            }}
            onBlur={() => {
              setFormFocused('');
            }}
            className={classNames(
              css.cardNum,
              isFormEmpty.cardNum || css.formActive,
              formFocused === 'cardNum' && css.formFocused
            )}
            options={{
              placeholder: '0000 0000 0000 0000',
              style: {
                base: {
                  fontSize: `${fontSize}px`,
                  color: '#C4C4C4',
                  ':-webkit-autofill': {
                    color: '#C4C4C4',
                  },
                },
                complete: {
                  fontSize: `${fontSize}px`,
                  color: '#C4C4C4',
                  ':-webkit-autofill': {
                    color: '#C4C4C4',
                  },
                },
              },
            }}
          />
          <div className={classNames(css.inputFlex)}>
            <div>
              <div className={classNames(css.inputLabel)}>名義</div>
              <input
                onChange={(e) => {
                  setIsFormEmptyByProp('holdersName', e.target.value?.length === 0);
                }}
                onFocus={() => {
                  setFormFocused('holdersName');
                }}
                onBlur={() => {
                  setFormFocused('');
                }}
                className={classNames(
                  css.holdersName,
                  isFormEmpty.holdersName || css.formActive,
                  formFocused === 'holdersName' && css.formFocused
                )}
                placeholder="MISUZU TEIRA"
              />
            </div>
            <div>
              <div className={classNames(css.inputLabel)}>有効期限</div>
              <CardExpiryElement
                onChange={(e) => {
                  setIsFormEmptyByProp('cardExpiry', e.empty);
                }}
                onFocus={() => {
                  setFormFocused('cardExpiry');
                }}
                onBlur={() => {
                  setFormFocused('');
                }}
                className={classNames(
                  css.cardExpiry,
                  isFormEmpty.cardExpiry || css.formActive,
                  formFocused === 'cardExpiry' && css.formFocused
                )}
                options={{
                  placeholder: 'MM / YY',
                  style: {
                    base: {
                      fontSize: `${(fontSize * 3) / 4}px`,
                      color: '#C4C4C4',
                      ':-webkit-autofill': {
                        color: '#C4C4C4',
                      },
                    },
                    complete: {
                      fontSize: `${(fontSize * 3) / 4}px`,
                      color: '#C4C4C4',
                      ':-webkit-autofill': {
                        color: '#C4C4C4',
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className={classNames(css.faceShadow)}></div>
        <div className={classNames(css.flipSide)}>
          <div className={classNames(css.magneticLine)}></div>
          <div className={classNames(css.cvcWrap)}>
            <div className={classNames(css.inputLabel)}>CVC</div>
            <CardCvcElement
              onChange={(e) => {
                setIsFormEmptyByProp('cvc', e.empty);
              }}
              onFocus={() => {
                setFormFocused('cvc');
              }}
              onBlur={() => {
                setFormFocused('');
              }}
              className={classNames(
                css.cvc,
                isFormEmpty.cvc || css.formActive,
                formFocused === 'cvc' && css.formFocused
              )}
              options={{
                placeholder: '123',
                style: {
                  base: { fontSize: `${fontSize}px`, color: '#C4C4C4' },
                  complete: { fontSize: `${fontSize}px`, color: '#C4C4C4' },
                },
              }}
            />
          </div>
        </div>
        {showHelp ? (
          <div>
            <img
              src={image['close.webp']}
              className={classNames(css.help)}
              onClick={() => {
                setShowHelp(false);
              }}
            />
            <div className={classNames(css.helpPopUp)}>
              <p>セキュリティーコード</p>
              <img src={image['security_code.webp']} />
            </div>
          </div>
        ) : (
          <img
            src={image['help.webp']}
            className={classNames(css.help)}
            onClick={() => {
              setShowHelp(true);
            }}
          />
        )}
      </div>
      {error && <div className={classNames(css.stripeError)}>{error}</div>}
      <LinkButton
        className={classNames(css.linkButton)}
        width={inputFormWidth}
        height={linkButtonHeight}
        text="確認画面へ進む"
        color="black"
        active={isButtonActive}
        loading={buttonIsLoading}
        onClick={() => {
          fetchPaymentMethod();
        }}
      />
      <LinkButton
        width={inputFormWidth}
        height={linkButtonHeight}
        text="お客様情報に戻る"
        color="white"
        arrow="left"
        active={true}
        onClick={() => {
          navigate('/tachibana-ran-collabo/takeout/customer-info');
        }}
      />
    </>
  );
};
