import { useState, useEffect } from 'react';
import { validation, formatPhoneNum, insertTwoHyphens, insertHyphen, searchPostalCode } from '../../utils/form';
import { InputForm } from '../../components/inputForm/inputForm';
import { LinkButton } from '../../components/linkButton';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';
import { addKamaitachiTotalState, addKamaitachiAddressState } from '../../store/atoms';
import { importAll } from '../../utils/image';
import { Wrapper } from './scss';
import { Loader } from '../../snippets/loader';
import { FormHeader } from '../../components/fiveStepBar';

export const KamaitachiAddressInfo = () => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const navigate = useNavigate();
  const total = useRecoilValue(addKamaitachiTotalState);
  const inputFormWidth = [340, 512, 720];
  const inputFormHalfWidth = [160, 246, 350];
  const zipCodeInputWidth = [140, 160, 180];
  const inputFormHeight = [32, 38, 44];
  const linkButtonHeight = [48, 52, 60];
  const [recoilAddress, setRecoilAddress] = useRecoilState(addKamaitachiAddressState);
  const [isValid, setIsValid] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [address, setAddress] = useState(<>郵便番号を入力してください</>);
  const [showBuildingForm, setShowBuildingForm] = useState(false);
  const [showCorporationForm, setShowCorporationForm] = useState(false);
  // MEMO: URLパラメータから予約IDを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const id = query.get('id');
  const [value, setValue] = useState({});
  const [form, setForm] = useState({
    lastName: 'not filled',
    firstName: 'not filled',
    lastNameKana: 'not filled',
    firstNameKana: 'not filled',
    phoneNum: 'not filled',
    zipCode: 'not filled',
    street: 'not filled',
    building: 'valid',
    corporation: 'valid',
  });
  const setValueByProp = (key, value) => {
    setValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const setFormByProp = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateItem = (value) => {
    const newArray = [...recoilAddress.list];
    newArray[id] = value;
    setRecoilAddress({
      selected: parseInt(id),
      list: newArray,
    });
  };

  const removeItem = () => {
    const newArray = recoilAddress.list.filter((_, i) => i !== parseInt(id));
    setRecoilAddress({
      selected: 0,
      list: newArray,
    });
  };

  const onChangeInputForm = (event, prop, validationType) => {
    const value = event.target.value;
    setValueByProp(prop, value);
    if (!value) {
      setFormByProp(prop, 'not filled');
      setIsValid(false);
    } else if (validation[validationType].test(value)) {
      setFormByProp(prop, 'valid');
    } else {
      setFormByProp(prop, 'invalid');
      setIsValid(false);
    }
  };

  // MEMO : 必須でないフォームの処理
  const onChangeNotRequired = (event, prop, validationType) => {
    const value = event.target.value;
    setValueByProp(prop, value);
    if (!value) {
      setFormByProp(prop, 'valid');
    } else if (validation[validationType].test(value)) {
      setFormByProp(prop, 'valid');
    } else {
      setFormByProp(prop, 'invalid');
    }
  };

  /**enter入力で次のフォームへ移動 */
  const onKeyUpInputForm = (event, currentProp, nextProp) => {
    if (form[currentProp] === 'valid' && event.keyCode === 13) {
      document.getElementById(nextProp).focus();
    }
  };

  /**enter入力で次のフォーム（建物名・会社名）のタブを開く */
  // TODO : Shift + Tabでも開いてしまうので、修正が必要
  const onKeyDownInputForm = (event, currentProp) => {
    if (form[currentProp] === 'valid' && (event.keyCode === 13 || event.keyCode === 9)) {
      if (currentProp === 'street') {
        setShowBuildingForm(true);
      } else if (currentProp === 'building') {
        setShowCorporationForm(true);
      }
    }
  };

  /**ふりがなの自動入力 */
  const fillKanaAutomatically = (event, prop) => {
    const value = event.target.value;
    if (validation.hiragana.test(value)) {
      setValueByProp(prop + 'Kana', value);
      setFormByProp(prop + 'Kana', 'valid');
    }
  };

  // MEMO : 電話番号はハイフン等の整形があるので、個別で処理
  const onChangePhoneNum = (event) => {
    const format = formatPhoneNum(event);
    const shapedPhoneNum = insertTwoHyphens(event, format[0], format[1], format[2]);
    setValueByProp('phoneNum', shapedPhoneNum);
    const value = event.target.value.replaceAll('-', '').replaceAll(' ', '');
    if (!value) {
      setFormByProp('phoneNum', 'not filled');
      setIsValid(false);
    } else if (validation.phoneNum.test(value)) {
      setFormByProp('phoneNum', 'valid');
    } else {
      setFormByProp('phoneNum', 'invalid');
      setIsValid(false);
    }
  };

  // MEMO : 郵便番号も、個別で処理（ハイフンの挿入と住所の取得）
  const onChangeZipCode = async (event) => {
    setAddress(<Loader color="161c1c" size={[16, 18, 20]} />);
    const shapedZipCode = insertHyphen(event, 3, 4);
    setValueByProp('zipCode', shapedZipCode);
    const value = event.target.value.replaceAll('-', '').replaceAll(' ', '').substr(0, 7);
    if (!value) {
      setFormByProp('zipCode', 'not filled');
      setAddress(<>郵便番号を入力してください</>);
      setIsValid(false);
    } else if (validation.zipCode.test(value)) {
      const res = await searchPostalCode(value);
      if (res === null) {
        setFormByProp('zipCode', 'invalid');
        setAddress(<>郵便番号が正しくありません</>);
      } else {
        setFormByProp('zipCode', 'valid');
        setAddress(res.prefecture + res.city + res.suburb);
        setValueByProp('prefecture', res.prefecture);
        setValueByProp('city', res.city);
        setValueByProp('suburb', res.suburb);
      }
    } else {
      setFormByProp('zipCode', 'invalid');
      setAddress(<>郵便番号を入力してください</>);
      setIsValid(false);
    }
  };

  // MEMO : フォーム全体が有効かどうかを判定する
  useEffect(() => {
    if (isValid) return;
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (!isValid) setButtonIsLoading(false);
  }, [isValid]);

  useEffect(() => {
    // MEMO : 直接アクセスされた場合のリダイレクト
    if (total.totalCount === 0) {
      navigate('/tachibana-ran-collabo');
    }
    if (recoilAddress.list[id]) {
      setValue(recoilAddress.list[id]);
      setForm({
        lastName: 'valid',
        firstName: 'valid',
        lastNameKana: 'valid',
        firstNameKana: 'valid',
        phoneNum: 'valid',
        zipCode: 'valid',
        street: 'valid',
        building: 'valid',
        corporation: 'valid',
      });
    }
  }, []);

  return (
    <Wrapper
      showBuildingForm={showBuildingForm}
      showCorporationForm={showCorporationForm}
      inputFormWidth={inputFormWidth}
      inputFormHalfWidth={inputFormHalfWidth}
      inputFormHeight={inputFormHeight}
      linkButtonHeight={linkButtonHeight}
    >
      <FormHeader step={2} title={recoilAddress.list[id] ? '配送先編集' : '配送先追加'} />
      {recoilAddress.list[id] && (
        <div
          className="deleteButton"
          onClick={() => {
            removeItem();
            navigate('/tachibana-ran-collabo/delivery/delivery-info');
          }}
        >
          削除する
        </div>
      )}
      <div className="formFlex">
        <InputForm
          className="marginBottom marginTop"
          required={true}
          title="お名前"
          help="姓名合わせて全角16文字まで"
          width={inputFormHalfWidth}
          height={inputFormHeight}
          placeholder="定良"
          description="姓"
          id="lastName"
          value={value.lastName || ''}
          onChange={(e) => {
            onChangeInputForm(e, 'lastName', 'string');
            fillKanaAutomatically(e, 'lastName');
          }}
          onKeyUp={(e) => {
            onKeyUpInputForm(e, 'lastName', 'firstName');
          }}
          check={form.lastName === 'valid'}
          error={form.lastName === 'invalid'}
          errorMessage="姓に無効な文字や空白を含んでいます"
        />
        <div className="fName">
          <InputForm
            className="marginBottom marginTop"
            width={inputFormHalfWidth}
            height={inputFormHeight}
            placeholder="美鈴"
            description="名"
            id="firstName"
            value={value.firstName || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'firstName', 'string');
            }}
            onKeyUp={(e) => {
              onKeyUpInputForm(e, 'firstName', 'lastNameKana');
              fillKanaAutomatically(e, 'firstName');
            }}
            check={form.firstName === 'valid'}
            error={form.firstName === 'invalid'}
            errorMessage="名に無効な文字や空白を含んでいます"
          />
        </div>
      </div>
      <div className="formFlex">
        <InputForm
          className="marginBottom"
          required={true}
          title="ふりがな"
          help="せいめい合わせて全角25文字まで"
          width={inputFormHalfWidth}
          height={inputFormHeight}
          placeholder="ていら"
          description="せい"
          id="lastNameKana"
          value={value.lastNameKana || ''}
          onChange={(e) => {
            onChangeInputForm(e, 'lastNameKana', 'hiragana');
          }}
          onKeyUp={(e) => {
            onKeyUpInputForm(e, 'lastNameKana', 'firstNameKana');
          }}
          check={form.lastNameKana === 'valid'}
          error={form.lastNameKana === 'invalid'}
          errorMessage="ひらがなで入力してください"
        />
        <div className="fName">
          <InputForm
            className="marginBottom"
            width={inputFormHalfWidth}
            height={inputFormHeight}
            placeholder="みすず"
            description="めい"
            id="firstNameKana"
            value={value.firstNameKana || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'firstNameKana', 'hiragana');
            }}
            onKeyUp={(e) => {
              onKeyUpInputForm(e, 'firstNameKana', 'mailAddress');
            }}
            check={form.firstNameKana === 'valid'}
            error={form.firstNameKana === 'invalid'}
            errorMessage="ひらがなで入力してください"
          />
        </div>
      </div>
      <InputForm
        className="marginBottom"
        required={true}
        title="電話番号"
        help="半角数字のみ11桁まで"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="090-0000-0000"
        description="ハイフン入力不要"
        id="phoneNum"
        type="tel"
        value={value.phoneNum || ''}
        onChange={(e) => {
          onChangePhoneNum(e);
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'phoneNum', 'zipCode');
        }}
        check={form.phoneNum === 'valid'}
        error={form.phoneNum === 'invalid'}
        errorMessage="10~11桁の有効な電話番号を入力してください"
      />
      <InputForm
        className="marginBottom"
        required={true}
        title="郵便番号"
        help="半角数字のみ7桁"
        width={zipCodeInputWidth}
        height={inputFormHeight}
        placeholder="000-0000"
        description="ハイフン入力不要"
        id="zipCode"
        value={value.zipCode || ''}
        onChange={(e) => {
          onChangeZipCode(e);
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'zipCode', 'street');
        }}
        check={form.zipCode === 'valid'}
        error={form.zipCode === 'invalid'}
        errorMessage="7桁の有効な郵便番号を入力してください"
      />
      <div className="address">{address}</div>
      <InputForm
        className="marginBottom marginTop"
        required={true}
        title="番地"
        help="全角16文字（半角32文字）まで"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="1-2-3"
        id="street"
        value={value.street || ''}
        onChange={(e) => {
          onChangeInputForm(e, 'street', 'textArea');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'street', 'building');
        }}
        onKeyDown={(e) => {
          onKeyDownInputForm(e, 'street');
        }}
        check={form.street === 'valid'}
        error={form.street === 'invalid'}
        errorMessage=""
      />
      <div
        className="description"
        onClick={() => {
          setShowBuildingForm(!showBuildingForm);
        }}
      >
        <img src={image['arrow_down.svg']} className="imgBuilding img" />
        建物名/部屋番号
      </div>
      <InputForm
        className="marginBottom building"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="ティラミスハウス 101"
        id="building"
        value={value.building || ''}
        onChange={(e) => {
          onChangeNotRequired(e, 'building', 'textArea');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'building', 'corporation');
        }}
        onKeyDown={(e) => {
          onKeyDownInputForm(e, 'building');
        }}
        check={form.building === 'valid' && value.building}
        error={form.building === 'invalid' && value.building}
        errorMessage=""
      />
      <div
        className="description"
        onClick={() => {
          setShowCorporationForm(!showCorporationForm);
        }}
      >
        <img src={image['arrow_down.svg']} className="imgCorporation img" />
        会社名/部門
      </div>
      <InputForm
        className="marginBottom corporation"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="株式会社〇〇"
        id="corporation"
        value={value.corporation || ''}
        onChange={(e) => {
          onChangeNotRequired(e, 'corporation', 'textArea');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'corporation', '');
        }}
        check={form.corporation === 'valid' && value.corporation}
        error={form.corporation === 'invalid' && value.corporation}
        errorMessage=""
      />
      <LinkButton
        className="linkButton"
        width={inputFormWidth}
        height={linkButtonHeight}
        text={recoilAddress.list[id] ? '修正する' : '追加する'}
        color="black"
        active={isValid}
        loading={buttonIsLoading}
        onClick={() => {
          setButtonIsLoading(true);
          updateItem(value);
          navigate('/tachibana-ran-collabo/delivery/delivery-info');
        }}
      />
      <LinkButton
        width={inputFormWidth}
        height={linkButtonHeight}
        text="配送に戻る"
        color="white"
        arrow="left"
        active={true}
        onClick={() => {
          setButtonIsLoading(true);
          navigate('/tachibana-ran-collabo/delivery/delivery-info');
        }}
      />
    </Wrapper>
  );
};
