import { useState, useEffect } from 'react';
import { validation, formatPhoneNum, insertTwoHyphens, insertHyphen, searchPostalCode } from '../../utils/form';
import { InputForm } from '../../components/inputForm/inputForm';
import { LinkButton } from '../../components/linkButton';
import { useNavigate } from 'react-router-dom';

import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  addKamaitachiCustomerState,
  addKamaitachiPaymentState,
  addKamaitachiReservationIdState,
  addKamaitachiTotalState,
  addKamaitachiAddressState,
  addKamaitachiFormState,
} from '../../store/atoms';
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../zz_general/utils/configs/firebase';
import { generateUniqueID } from '../../utils/generateId';
import { importAll } from '../../utils/image';
import { Wrapper } from './scss';
import { Loader } from '../../snippets/loader';
import { FormHeader } from '../../components/fiveStepBar';

export const KamaitachiCustomerInfoDelivery = () => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const navigate = useNavigate();
  const inputFormWidth = [340, 512, 720];
  const inputFormHalfWidth = [160, 246, 350];
  const zipCodeInputWidth = [140, 160, 180];
  const inputFormHeight = [32, 38, 44];
  const linkButtonHeight = [48, 52, 60];
  const setReservationId = useSetRecoilState(addKamaitachiReservationIdState);
  const setPayment = useSetRecoilState(addKamaitachiPaymentState);
  const [total, setTotal] = useRecoilState(addKamaitachiTotalState);
  const [recoilAddress, setRecoilAddress] = useRecoilState(addKamaitachiAddressState);
  const [recoilForm, setRecoilForm] = useRecoilState(addKamaitachiFormState);
  const [isValid, setIsValid] = useState(recoilForm.deliveryFormIsValid);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [customer, setCustomer] = useRecoilState(addKamaitachiCustomerState);
  const [addressText, setAddressText] = useState('郵便番号を入力してください');
  const [isZipLoader, setIsZipLoader] = useState(false);
  const [showBuildingForm, setShowBuildingForm] = useState(false);
  const [showCorporationForm, setShowCorporationForm] = useState(false);
  const [form, setForm] = useState({
    lastName: recoilForm.content.lastName,
    firstName: recoilForm.content.firstName,
    lastNameKana: recoilForm.content.lastNameKana,
    firstNameKana: recoilForm.content.firstNameKana,
    mailAddress: recoilForm.content.mailAddress,
    phoneNum: recoilForm.content.phoneNum,
    zipCode: recoilForm.content.zipCode,
    street: recoilForm.content.street,
    building: 'valid',
    corporation: 'valid',
  });
  const setCustomerByProp = (key, value) => {
    setCustomer((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };
  const setFormByProp = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const setTotalByProp = (key, value) => {
    setTotal((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };

  const onChangeInputForm = (event, prop, validationType) => {
    const value = event.target.value;
    setCustomerByProp(prop, value);
    if (!value) {
      setFormByProp(prop, 'not filled');
      setIsValid(false);
    } else if (validation[validationType].test(value)) {
      setFormByProp(prop, 'valid');
    } else {
      setFormByProp(prop, 'invalid');
      setIsValid(false);
    }
  };

  // MEMO : 必須でないフォームの処理
  const onChangeNotRequired = (event, prop, validationType) => {
    const value = event.target.value;
    setCustomerByProp(prop, value);
    if (!value) {
      setFormByProp(prop, 'valid');
    } else if (validation[validationType].test(value)) {
      setFormByProp(prop, 'valid');
    } else {
      setFormByProp(prop, 'invalid');
    }
  };

  /**enter入力で次のフォームへ移動 */
  const onKeyUpInputForm = (event, currentProp, nextProp) => {
    if (form[currentProp] === 'valid' && event.keyCode === 13) {
      document.getElementById(nextProp).focus();
    }
  };

  /**enter入力で次のフォーム（建物名・会社名）のタブを開く */
  // TODO : Shift + Tabでも開いてしまうので、修正が必要
  const onKeyDownInputForm = (event, currentProp) => {
    if (form[currentProp] === 'valid' && (event.keyCode === 13 || event.keyCode === 9)) {
      if (currentProp === 'street') {
        setShowBuildingForm(true);
      } else if (currentProp === 'building') {
        setShowCorporationForm(true);
      }
    }
  };

  /**ふりがなの自動入力 */
  const fillKanaAutomatically = (event, prop) => {
    const value = event.target.value;
    if (validation.hiragana.test(value)) {
      setCustomerByProp(prop + 'Kana', value);
      setFormByProp(prop + 'Kana', 'valid');
    }
  };

  // MEMO : 電話番号はハイフン等の整形があるので、個別で処理
  const onChangePhoneNum = (event) => {
    const format = formatPhoneNum(event);
    const shapedPhoneNum = insertTwoHyphens(event, format[0], format[1], format[2]);
    setCustomerByProp('phoneNum', shapedPhoneNum);
    const value = event.target.value.replaceAll('-', '').replaceAll(' ', '');
    if (!value) {
      setFormByProp('phoneNum', 'not filled');
      setIsValid(false);
    } else if (validation.phoneNum.test(value)) {
      setFormByProp('phoneNum', 'valid');
    } else {
      setFormByProp('phoneNum', 'invalid');
      setIsValid(false);
    }
  };

  // MEMO : 郵便番号も、個別で処理（ハイフンの挿入と住所の取得）
  const onChangeZipCode = async (event) => {
    const shapedZipCode = insertHyphen(event, 3, 4);
    setCustomerByProp('zipCode', shapedZipCode);
    const value = event.target.value.replaceAll('-', '').replaceAll(' ', '').substr(0, 7);
    if (!value) {
      setFormByProp('zipCode', 'not filled');
      setAddressText('郵便番号を入力してください');
    } else if (validation.zipCode.test(value)) {
      setIsZipLoader(true);
      const res = await searchPostalCode(value);
      if (res === null) {
        setIsZipLoader(false);
        setFormByProp('zipCode', 'invalid');
        setAddressText('郵便番号が正しくありません');
      } else {
        setIsZipLoader(false);
        setFormByProp('zipCode', 'valid');
        setAddressText(`${res.prefecture} ${res.city} ${res.suburb}`);
        setCustomerByProp('prefecture', res.prefecture);
        setCustomerByProp('city', res.city);
        setCustomerByProp('suburb', res.suburb);
      }
    } else {
      setFormByProp('zipCode', 'invalid');
      setIsValid(false);
      setAddressText('郵便番号を入力してください');
    }
    setIsValid(false);
  };
  // MEMO : inputのtypeをemailにするとspace入力時にonClickが発火しないため、onKeyUpで代替する
  const onKeyUpMailAddress = (event) => {
    const value = event.target.value;

    if (event.keyCode === 32) {
      setFormByProp('mailAddress', 'invalid');
      setIsValid(false);
    } else if (!value) {
      setFormByProp('mailAddress', 'not filled');
      setIsValid(false);
    } else if (validation.mail.test(value)) setFormByProp('mailAddress', 'valid');
  };

  // MEMO : フォーム全体が有効かどうかを判定する
  useEffect(() => {
    if (isValid) return;
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (!isValid) setButtonIsLoading(false);
  }, [isValid]);

  useEffect(() => {
    // MEMO : 直接アクセスされた場合のリダイレクト
    if (total.totalCount === 0) {
      navigate('/tachibana-ran-collabo');
    }
    // MEMO : フォームの有効性をセット
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // MEMO : 6000円未満の場合、送料を追加
    if (total.totalPrice < 6000 && total.includeDeliveryFee === false) {
      setTotalByProp('totalPrice', total.totalPrice + 1000);
      setTotalByProp('includeDeliveryFee', true);
    }
    // MEMO : 予約IDの生成
    const collectionRef = collection(
      db,
      'kamaitachi',
      process.env.REACT_APP_IS_PROD === 'true' ? 'tachibana-collabo' : 'tachibana-collabo',
      'reservations'
    );
    const id = generateUniqueID(collectionRef);
    id.then((res) => {
      // eslint-disable-next-line no-console
      console.log(res);
      setReservationId(res);
      const docRef = doc(collectionRef, res);
      setDoc(docRef, {});
      // MEMO : 送信済みフラグをリセット
      setPayment((current) => {
        const future = JSON.parse(JSON.stringify(current));
        future['payPay']['sentData'] = false;
        return future;
      });
    });
  }, []);

  return (
    <Wrapper
      showBuildingForm={showBuildingForm}
      showCorporationForm={showCorporationForm}
      inputFormWidth={inputFormWidth}
      inputFormHalfWidth={inputFormHalfWidth}
      inputFormHeight={inputFormHeight}
      linkButtonHeight={linkButtonHeight}
    >
      <FormHeader step={1} title="お客様情報" />
      <div className="formFlex">
        <InputForm
          className="marginBottom marginTop"
          required={true}
          title="お名前"
          help="姓名合わせて全角16文字まで"
          width={inputFormHalfWidth}
          height={inputFormHeight}
          placeholder="定良"
          description="姓"
          id="lastName"
          value={customer.lastName || ''}
          onChange={(e) => {
            onChangeInputForm(e, 'lastName', 'string');
            fillKanaAutomatically(e, 'lastName');
          }}
          onKeyUp={(e) => {
            onKeyUpInputForm(e, 'lastName', 'firstName');
          }}
          check={form.lastName === 'valid'}
          error={form.lastName === 'invalid'}
          errorMessage="姓に無効な文字や空白を含んでいます"
        />
        <div className="fName">
          <InputForm
            className="marginBottom marginTop"
            width={inputFormHalfWidth}
            height={inputFormHeight}
            placeholder="美鈴"
            description="名"
            id="firstName"
            value={customer.firstName || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'firstName', 'string');
            }}
            onKeyUp={(e) => {
              onKeyUpInputForm(e, 'firstName', 'lastNameKana');
              fillKanaAutomatically(e, 'firstName');
            }}
            check={form.firstName === 'valid'}
            error={form.firstName === 'invalid'}
            errorMessage="名に無効な文字や空白を含んでいます"
          />
        </div>
      </div>
      <div className="formFlex">
        <InputForm
          className="marginBottom"
          required={true}
          title="ふりがな"
          help="せいめい合わせて全角25文字まで"
          width={inputFormHalfWidth}
          height={inputFormHeight}
          placeholder="ていら"
          description="せい"
          id="lastNameKana"
          value={customer.lastNameKana || ''}
          onChange={(e) => {
            onChangeInputForm(e, 'lastNameKana', 'hiragana');
          }}
          onKeyUp={(e) => {
            onKeyUpInputForm(e, 'lastNameKana', 'firstNameKana');
          }}
          check={form.lastNameKana === 'valid'}
          error={form.lastNameKana === 'invalid'}
          errorMessage="ひらがなで入力してください"
        />
        <div className="fName">
          <InputForm
            className="marginBottom"
            width={inputFormHalfWidth}
            height={inputFormHeight}
            placeholder="みすず"
            description="めい"
            id="firstNameKana"
            value={customer.firstNameKana || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'firstNameKana', 'hiragana');
            }}
            onKeyUp={(e) => {
              onKeyUpInputForm(e, 'firstNameKana', 'mailAddress');
            }}
            check={form.firstNameKana === 'valid'}
            error={form.firstNameKana === 'invalid'}
            errorMessage="ひらがなで入力してください"
          />
        </div>
      </div>
      <InputForm
        className="marginBottom"
        required={true}
        title="メールアドレス"
        help="半角英数字記号のみ"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="tiramisu@bene-regalo.com"
        id="mailAddress"
        type="email"
        value={customer.mailAddress || ''}
        onChange={(e) => {
          onChangeInputForm(e, 'mailAddress', 'mail');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'mailAddress', 'phoneNum');
          onKeyUpMailAddress(e);
        }}
        check={form.mailAddress === 'valid'}
        error={form.mailAddress === 'invalid'}
        errorMessage="有効なメールアドレスを入力してください"
      />
      <div className="checkBoxWrap">
        <img
          src={image[`check_box_${customer.receiveDM ? 'active' : 'passive'}.svg`]}
          className="checkBox"
          onClick={() => {
            setCustomerByProp('receiveDM', !customer.receiveDM);
          }}
        />
        限定商品の案内などをメールで受け取る
      </div>
      <InputForm
        className="marginBottom"
        required={true}
        title="電話番号"
        help="半角数字のみ11桁まで"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="090-0000-0000"
        description="ハイフン入力不要"
        id="phoneNum"
        type="tel"
        value={customer.phoneNum || ''}
        onChange={(e) => {
          onChangePhoneNum(e);
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'phoneNum', 'zipCode');
        }}
        check={form.phoneNum === 'valid'}
        error={form.phoneNum === 'invalid'}
        errorMessage="10~11桁の有効な電話番号を入力してください"
      />
      <InputForm
        className="marginBottom"
        required={true}
        title="郵便番号"
        help="半角数字のみ7桁"
        width={zipCodeInputWidth}
        height={inputFormHeight}
        placeholder="000-0000"
        description="ハイフン入力不要"
        id="zipCode"
        type="tel"
        value={customer.zipCode || ''}
        onChange={(e) => {
          onChangeZipCode(e);
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'zipCode', 'street');
        }}
        check={form.zipCode === 'valid'}
        error={form.zipCode === 'invalid'}
        errorMessage="7桁の有効な郵便番号を入力してください"
      />

      {isZipLoader ? <Loader color="161c1c" size={[16, 18, 20]} /> : <div className="addressText">{addressText}</div>}

      <InputForm
        className="marginBottom marginTop"
        required={true}
        title="番地"
        help="全角16文字（半角32文字）まで"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="1-2-3"
        id="street"
        value={customer.street || ''}
        onChange={(e) => {
          onChangeInputForm(e, 'street', 'textArea');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'street', 'building');
        }}
        onKeyDown={(e) => {
          onKeyDownInputForm(e, 'street');
        }}
        check={form.street === 'valid'}
        error={form.street === 'invalid'}
        errorMessage=""
      />
      <div
        className="description"
        onClick={() => {
          setShowBuildingForm(!showBuildingForm);
        }}
      >
        <img src={image['arrow_down.svg']} className="imgBuilding img" />
        建物名/部屋番号
      </div>
      <InputForm
        className="marginBottom building"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="ティラミスハウス 101"
        id="building"
        value={customer.building || ''}
        onChange={(e) => {
          onChangeNotRequired(e, 'building', 'textArea');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'building', 'corporation');
        }}
        onKeyDown={(e) => {
          onKeyDownInputForm(e, 'building');
        }}
        check={form.building === 'valid' && customer.building}
        error={form.building === 'invalid' && customer.building}
        errorMessage=""
      />
      <div
        className="description"
        onClick={() => {
          setShowCorporationForm(!showCorporationForm);
        }}
      >
        <img src={image['arrow_down.svg']} className="imgCorporation img" />
        会社名/部門
      </div>
      <InputForm
        className="marginBottom corporation"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="株式会社〇〇"
        id="corporation"
        value={customer.corporation || ''}
        onChange={(e) => {
          onChangeNotRequired(e, 'corporation', 'textArea');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'corporation', '');
        }}
        check={form.corporation === 'valid' && customer.corporation}
        error={form.corporation === 'invalid' && customer.corporation}
        errorMessage=""
      />
      <LinkButton
        className="linkButton"
        width={inputFormWidth}
        height={linkButtonHeight}
        text="配送へ進む"
        color="black"
        active={isValid}
        loading={buttonIsLoading}
        onClick={() => {
          setButtonIsLoading(true);
          setRecoilForm({
            ...recoilForm,
            deliveryFormIsValid: isValid,
            content: {
              ...recoilForm.content,
              ...form,
            },
          });
          const newArray = [...recoilAddress.list];
          newArray[0] = {
            lastName: customer?.lastName,
            firstName: customer?.firstName,
            lastNameKana: customer?.lastNameKana,
            firstNameKana: customer?.firstNameKana,
            phoneNum: customer?.phoneNum,
            zipCode: customer?.zipCode,
            prefecture: customer?.prefecture,
            city: customer?.city,
            suburb: customer?.suburb,
            street: customer?.street,
            building: customer?.building || '',
            corporation: customer?.corporation || '',
          };
          setRecoilAddress({
            ...recoilAddress,
            list: newArray,
          });
          navigate('/tachibana-ran-collabo/delivery/delivery-info');
        }}
      />
      <LinkButton
        width={inputFormWidth}
        height={linkButtonHeight}
        text="カートに戻る"
        color="white"
        arrow="left"
        active={true}
        onClick={() => {
          setButtonIsLoading(true);
          setRecoilForm({
            ...recoilForm,
            deliveryFormIsValid: isValid,
            content: {
              ...recoilForm.content,
              ...form,
            },
          });
          navigate('/tachibana-ran-collabo');
        }}
      />
    </Wrapper>
  );
};
