// rechallenge.js
import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import ModalRechallenge from './components/modal';
import { Wrapper } from './rechallenge.scss';

import { db, functions } from '../../zz_general/utils/configs/firebase';
import { QuizHeader } from '../components/quizHeader';
import classNames from 'classnames';
import { consoleError, consoleLog } from '../../zz_general/utils/snippets/console';
import { doc, updateDoc } from 'firebase/firestore';
import { updateUser } from '../utils/update';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { QuizUserState } from '../store/atom';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { QuizWrapper } from '../components/quizWrapper';
import { isProd, publicUrl } from '../../zz_general/utils/snippets/env';
import { changePage } from '../utils/navigate';
import { termsAndPolicyState } from '../../lp-legacy/atoms';
import { BRv2_quiz_verifyTweet, BRv2_lark_postMassage } from '../../zz_general/utils/functions';

const imgPath = (fileName) => publicUrl + '/quiz/rechallenge/images/' + fileName;

export const QuizRechallenge = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const [link, setLink] = useState('');
  const [error, setError] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useRecoilState(QuizUserState);
  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);

  const handleLinkChange = (e) => {
    setLink(e.target.value);
    setError('');
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (user.tweetVerified) {
      setError('すでにチャレンジ申請済みです');
      setLoading(false);
      return;
    }

    try {
      const result = await BRv2_quiz_verifyTweet(link);
      consoleLog('result:', result);
      // Check for the response data
      if (result.data.valid) {
        setModal(true);
        updateUser(user, setUser, { tweetVerified: true, challengeCount: user.challengeCount + 1 });
        BRv2_lark_postMassage(
          isProd ? 'quiz_error' : 'test',
          `再チャレンジを申請を受け付けました。：${link}\n\nUser: ${user.userInfo.sub}`
        );
      } else {
        setError(result.data.error || 'Please enter the correct X post link');
      }
    } catch (err) {
      // Error might come from CORS or other issues
      if (err.response && err.response.data) {
        // Server responded with a status other than 2xx
        setError('Error verifying tweet: ' + err.response.data.error);
      } else if (err.request) {
        // No response received from server
        setError('Error verifying tweet: No response from server');
      } else {
        // Other errors
        setError('Error verifying tweet: ' + err.message);
      }
    }
    setLoading(false);
  };

  const updateTermsAndPolicy = (key, boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = boolean;
      return future;
    });
  };

  // MEMO: State for responsive design
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };
  useEffect(() => {
    !user.loggedIn && changePage(navigate, '', 'rechallenge');

    // MEMO : 利用規約とプライバシーポリシーのページ遷移用の設定
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['backPage'] = '/quiz/rechallenge';
      return future;
    });

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <QuizWrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      pageIsLoading={false}
    >
      <Wrapper
        className="rechallenge"
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
      >
        <QuizHeader
          onClick={() => {
            changePage(navigate, query.get('prev'), 'rechallenge');
          }}
          title="再チャレンジ申請"
        />
        <div className="post_link_text">投稿リンク</div>
        <div className="linkContainer">
          <input
            id="link"
            type="link"
            value={link}
            onChange={handleLinkChange}
            placeholder="https://"
            className="link"
          />
        </div>
        <div className="rulesContainer">
          <div className={classNames('rule')}>※鍵アカウントでの投稿は無効となります。</div>
          <div className={classNames('rule')}>
            ※全問正解後、プレゼント発送までに投稿が無くなっていた場合、再チャレンジは無効となります。
          </div>
          <div className={classNames('rule', 'rule')}>
            ※問題のネタバレなどが確認された場合も、挑戦は無効となります。
          </div>
        </div>
        <div className="submitContainer">
          {error && <div className="errorText">{error}</div>}
          {modal && <ModalRechallenge handleSubmit={handleSubmit} />}
          <button
            className={classNames({
              ['submit']: link.trim() !== '',
              ['submitDisabled']: link.trim() === '',
              ['submitError']: error,
            })}
            onClick={handleSubmit}
            disabled={link.trim() === '' || loading}
          >
            <span className="buttonText">{loading ? '検証中...' : '送信して再チャレンジ'}</span>
          </button>
        </div>
        <div className="footer">
          <div className="privacy_terms">
            <span
              className="privacy"
              onClick={() => {
                updateTermsAndPolicy('policy', true);
                navigate('/policy');
              }}
            >
              プライバシーポリシー
            </span>
            <span
              className="terms"
              onClick={() => {
                updateTermsAndPolicy('terms', true);
                navigate('/policy');
              }}
            >
              利用規約
            </span>
          </div>
          <div className="privacy_terms">
            <span
              className="description"
              onClick={() => {
                navigate('/transaction-act');
              }}
            >
              特定商取引法に基づく表記
            </span>
            <a className="company" href="https://eightgood.com/">
              運営会社
            </a>
          </div>
          <div className="copyright">Copyright ©Eight Good Co., Ltd.</div>
        </div>
      </Wrapper>
    </QuizWrapper>
  );
};
