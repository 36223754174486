import { StyledDiv, responsiveStyle } from '../../utils/styled';
import styled from 'styled-components';

const multiplyList = (list, num) => {
  return [list[0] * num, list[1] * num, list[2] * num];
};

export const Wrapper = styled(StyledDiv)`
  p {
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }
  .title {
    ${(props) => {
      return responsiveStyle({
        width: props.width,
        height: multiplyList(props.height, 0.64),
        fontSize: multiplyList(props.height, 0.34),
        lineHeight: multiplyList(props.height, 0.34),
        paddingTop: multiplyList(props.height, 0.15),
        paddingBottom: multiplyList(props.height, 0.15),
      });
    }}
  }
  .subtitle {
    ${(props) => {
      return responsiveStyle({
        width: props.width,
        height: multiplyList(props.height, 0.64),
        fontSize: multiplyList(props.height, 0.30),
        lineHeight: multiplyList(props.height, 0.30),
        paddingTop: multiplyList(props.height, 0.15),
        paddingBottom: multiplyList(props.height, 0.15),
      });
    }}
  }
  .pullDownWrap {
    border: 1px solid #000;
    ${(props) => {
      return responsiveStyle({
        borderRadius: multiplyList(props.height, 0.125),
      });
    }}
    .pullDown {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      ${(props) => {
        return responsiveStyle({
          width: props.width,
          height: props.height,
          fontSize: multiplyList(props.height, 0.34),
          lineHeight: multiplyList(props.height, 0.34),
          padding: multiplyList(props.height, 0.33),
        });
      }}

      img {
        rotate: var(--arrowVector);
      }
    }

    .choice {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      ${(props) => {
        return responsiveStyle({
          width: props.width,
          height: props.height,
          fontSize: multiplyList(props.height, 0.34),
          lineHeight: multiplyList(props.height, 0.34),
          padding: multiplyList(props.height, 0.33),
        });
      }}
    }
  }
`;
