import { useEffect, useState, useRef } from 'react';
import { Wrapper } from './successPrize.scss';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { collection, getDocs, doc, updateDoc, getDoc, addDoc } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';
import { consoleError, consoleLog } from '../../zz_general/utils/snippets/console';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { QuizSoundState, QuizState, QuizUserState } from '../store/atom';
import { useLocation, useNavigate } from 'react-router-dom';
import { QuizWrapper } from '../components/quizWrapper';
import { isProd, publicUrl } from '../../zz_general/utils/snippets/env';
import ReactHowler from 'react-howler';
import { BRv2_line_sendMessage } from '../../zz_general/utils/functions';
import { changePage } from '../utils/navigate';
import { BRv2_lark_postMassage } from '../../zz_general/utils/functions';
import { updateUser } from '../utils/update';

const imgPath = (fileName) => publicUrl + '/quiz/successPrize/images/' + fileName;
const audioPath = (fileName) => publicUrl + '/quiz/successPrize/sounds/' + fileName;

const lineText = `
【要返信】
ティラミスクイズの全問正解おめでとうございます！

賞品のお受け取り方法についてご案内しますので、まずはこちらの自動送信メッセージに下記をコピーしてご返信ください。

__________コピーこの下から＿＿＿＿＿

・商品のお送り先
氏名：（姓  名）（せい  めい）
郵便番号：
住所：
番地：
電話番号：
メールアドレス：

__________コピーこの上まで＿＿＿＿＿

ご返信がない場合、賞品の発送ができないので、ご確認のほどよろしくお願いいたします。

`;

const defaultPrizeDetails = {
  fullset: {
    src: imgPath('first_prize.webp'),
    topTextPart1: 'BENE REGALO',
    topTextPart2: 'フルセット 15,500円相当',
    bottomTextPart1: 'Primo Tiramisu in Box',
    bottomTextPart2: 'Tiramisu Financier Box',
    bottomTextPart3: 'Tiramisu Macaron Box',
    bottomTextPart4: 'Tiramisu Gelato MASCAR3/ESPRESSO3',
    bottomTextPart5: 'Namachoco Tiramisu',
  },
  amazon10000: {
    src: imgPath('second_prize.webp'),
    topTextPart1: 'Amazon ギフトカード',
    topTextPart2: '10,000円分',
  },
  set: {
    src: imgPath('third_prize.webp'),
    topTextPart1: 'BENE REGALO',
    topTextPart2: '定番3セット10,500円相当',
    bottomTextPart1: 'Primo Tiramisu in Box',
    bottomTextPart2: 'Tiramisu Financier Box',
    bottomTextPart3: 'Tiramisu Macaron Box',
  },
  amazon5000: {
    src: imgPath('fourth_prize.webp'),
    topTextPart1: 'Amazonギフトカード',
    topTextPart2: '5,000円分',
  },
  box: {
    src: imgPath('fifth_prize.webp'),
    topTextPart1: 'Primo Tiramisu in Box',
    topTextPart2: '6,000円相当',
  },
};

export const QuizSuccessPrize = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [bodyWidth, setBodyWidth] = useState([]);
  const [screenOrientation, setScreenOrientation] = useState('vertical');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [prizeDocId, setPrizeDocId] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const swiperRef = useRef(null);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [quizAttemptId, setQuizAttemptId] = useState('');
  const [totalPrizes, setTotalPrizes] = useState(0);
  const [PrizeDetails, setPrizeDetails] = useState(defaultPrizeDetails);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [attemptAmount, setAttemptAmount] = useState(0);

  const resetQuiz = useResetRecoilState(QuizState);

  const [user, setUser] = useRecoilState(QuizUserState);
  const quiz = useRecoilValue(QuizState);
  const sound = useRecoilValue(QuizSoundState);

  const [congratsAudio, setCongratsAudio] = useState(false);
  const congratsAudioRef = useRef(null);

  const [selectAudio, setSelectAudio] = useState(false);
  const selectAudioRef = useRef(null);

  const resetAudio = (audioRef) => {
    if (audioRef.current) {
      audioRef.current.seek(0);
    }
  };

  const resizeSwiper = () => {
    if (window.outerWidth > window.outerHeight) {
      Object.keys(PrizeDetails).length < 4 ? setSlidesToShow(1) : setSlidesToShow(3);
    } else {
      setSlidesToShow(1);
    }
  };

  const handleResize = () => {
    setScreenWidth([375, 834, 1440]);
    setScreenHeight([window.outerHeight, window.outerHeight, window.outerHeight]);
    if (window.outerWidth > window.outerHeight) {
      setScreenOrientation('horizontal');
      setBodyWidth([300, 420, 600]);
    } else {
      setScreenOrientation('vertical');
      setBodyWidth([375, 600, 1000]);
    }
  };

  const fetchAttemptAmount = async () => {
    try {
      const collectionRef = collection(
        db,
        'quiz',
        isProd ? 'quiz' : 'quiz-test',
        'users',
        user.userInfo.sub,
        'quizAttempts'
      );
      const snapshot = await getDocs(collectionRef);

      let attemptAmount = 0;
      snapshot.forEach(() => {
        attemptAmount++;
      });

      setAttemptAmount(attemptAmount);
    } catch (e) {
      consoleError('Error Fetching achieved users: ', e);
      return 0;
    }
  };

  const handleClick = async () => {
    setPageIsLoading(true);
    setCongratsAudio(false);
    if (!prizeDocId) return;

    try {
      const prizeRef = doc(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'prizes', prizeDocId);

      const prizeSnap = await getDoc(prizeRef);
      if (!prizeSnap.exists()) {
        consoleLog('No such prize document!');
        setPageIsLoading(false);
        return;
      }

      const prizeData = prizeSnap.data();
      if (prizeData.selected <= 0) {
        consoleLog('Prize value is already 0');
        setPageIsLoading(false);
        return;
      }

      await updateDoc(prizeRef, {
        selected: prizeData.selected - 1,
      });

      consoleLog(`${prizeDocId} prize updated to ${prizeData.selected - 1}`);
    } catch (error) {
      consoleError('Error updating prize document: ', error);
      setPageIsLoading(false);
      return;
    }

    try {
      updateUser(user, setUser, { achieved: true });
      consoleLog('User document updated successfully!');
    } catch (error) {
      consoleError('Error updating user document: ', error);
      return;
    }

    try {
      const quizRef = doc(
        db,
        'quiz',
        isProd ? 'quiz' : 'quiz-test',
        'users',
        user.userInfo.sub,
        'quizAttempts',
        quizAttemptId
      );
      await updateDoc(quizRef, {
        selectedPrize: prizeDocId,
      });
      await BRv2_lark_postMassage(
        isProd ? 'quiz_achieve' : 'test',
        `以下のユーザーが${attemptAmount + 1}回目の挑戦で全問正解いたしました！\n\n名前 : ${
          user.userInfo.name
        }\n\nメールアドレス : ${user.userInfo.email}\n\nuserID : ${
          user.userInfo.sub
        }\n\nquizAttemptID : ${quizAttemptId}\n\n賞品は${defaultPrizeDetails[prizeDocId].topTextPart1}${
          defaultPrizeDetails[prizeDocId].topTextPart2
        }を選びました！`
      );

      // MEMO: Send LINE message to user
      if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
        const res = await BRv2_line_sendMessage(user.userInfo.sub, lineText);
        if (!res.success) {
          BRv2_lark_postMassage(
            isProd ? 'quiz_error' : 'test',
            `ティラミスクイズ成功後のLINE送信に失敗: ${res.error}\n\nuserID:${user.userInfo.sub}\n\nquizAttemptID:${quizAttemptId}\n\nselectedPrize:${prizeDocId}（${defaultPrizeDetails[prizeDocId].topTextPart1}${defaultPrizeDetails[prizeDocId].topTextPart2}）`
          );
        }
      }
    } catch (error) {
      consoleError('Error updating prize document: ', error);
      setPageIsLoading(false);
      return;
    }

    setPageIsLoading(false);
    resetQuiz();
    changePage(navigate, 'congrats', 'success-prize');
  };

  const handleImageClick = async (name, index) => {
    if (sound.enabled) {
      resetAudio(selectAudioRef);
      setSelectAudio(true);
    }

    if (selectedImageIndex === index) {
      setSelectedImageIndex(null);
      consoleLog('Already selected this prize');
      return;
    }

    setWarningMessage(''); // Clear the warning message
    const prizeRef = doc(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'prizes', name);
    const prizeSnap = await getDoc(prizeRef);
    if (prizeSnap.exists()) {
      const prizeData = prizeSnap.data();
      if (prizeData.selected > 0) {
        setSelectedImageIndex(index);
        setPrizeDocId(name);
        consoleLog(`Current prize value: ${prizeData.selected}`);
      } else {
        consoleError('Prize value is 0, cannot select this prize');
        setWarningMessage('賞品が品切れとなってしまいました。他の賞品を選択してください。');
      }
    }
  };

  const goToNextPicture = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    consoleLog('next button clicked');
    setWarningMessage(''); // Clear the warning message
  };

  const goToPrevPicture = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
    consoleLog('previous button clicked');
    setWarningMessage(''); // Clear the warning message
  };

  const fetchPrizes = async () => {
    const prizes = {};
    let totalPrizes = 0;

    try {
      const prizesRef = collection(
        db,
        'quiz',
        process.env.REACT_APP_IS_PROD === 'true' ? 'quiz' : 'quiz-test',
        'prizes'
      );
      const prizesSnap = await getDocs(prizesRef);
      prizesSnap.forEach((doc) => {
        prizes[doc.id] = doc.data();
        totalPrizes += doc.data().selected;
      });
      setTotalPrizes(totalPrizes);
    } catch (error) {
      consoleError('Error getting documents: ', error);
      return;
    }

    const filteredPrizeDetails = Object.entries(PrizeDetails).reduce((acc, [key, value]) => {
      if (prizes[key]?.selected > 0) {
        acc[key] = value;
      }
      return acc;
    }, {});
    setPrizeDetails(filteredPrizeDetails);
    console.log(filteredPrizeDetails);
    Object.keys(filteredPrizeDetails).length < 4 && setSlidesToShow(1);
  };

  const updateFireStore = async () => {
    let quizAttemptId = '';

    try {
      const collectionRef = collection(
        db,
        'quiz',
        process.env.REACT_APP_IS_PROD === 'true' ? 'quiz' : 'quiz-test',
        'users',
        user.userInfo.sub,
        'quizAttempts'
      );
      const docRef = await addDoc(collectionRef, {
        attemptTime: new Date(),
        questionNumber: quiz.currentQuestion,
        exitReason: 'success',
        selectedOption: quiz.selectedOption,
        isSuccess: true,
        quizData: {
          score: quiz.score,
        },
      });
      quizAttemptId = docRef.id;
      setQuizAttemptId(quizAttemptId);
      consoleLog('Quiz document created successfully!');
    } catch (e) {
      consoleError('Error creating quiz document: ', e);
      return;
    }
  };

  useEffect(() => {
    resizeSwiper();
    window.addEventListener('resize', resizeSwiper);
    return () => window.removeEventListener('resize', resizeSwiper);
  }, [PrizeDetails]);

  useEffect(() => {
    const handlePopState = () => {
      alert('ブラウザバックボタンが押されたため、TOP画面にリダイレクトします。');
      changePage(navigate, '', 'success-prize');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    setPageIsLoading(true);
    !user.loggedIn && changePage(navigate, '', 'success-prize');
    user.achieved && changePage(navigate, '', 'success-prize');
    query.get('prev') !== 'questions' && changePage(navigate, '', 'success-prize');
    const async = async () => {
      await fetchAttemptAmount();
      await fetchPrizes();
      await updateFireStore();
    };
    async();
    setPageIsLoading(false);

    if (sound.enabled) {
      resetAudio(congratsAudioRef);
      setCongratsAudio(true);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <QuizWrapper
      screenWidth={screenWidth}
      screenHeight={screenHeight}
      screenOrientation={screenOrientation}
      bodyWidth={bodyWidth}
      pageIsLoading={pageIsLoading}
    >
      <ReactHowler
        src={audioPath('omedetou.mp3')}
        playing={congratsAudio}
        ref={congratsAudioRef}
        loop={false}
        volume={0.5}
        onEnd={() => {
          setCongratsAudio(false);
        }}
      />
      <ReactHowler
        src={audioPath('select.mp3')}
        playing={selectAudio}
        ref={selectAudioRef}
        loop={false}
        volume={0.5}
        onEnd={() => {
          setSelectAudio(false);
        }}
      />
      <Wrapper
        className="successPrize"
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        screenOrientation={screenOrientation}
        bodyWidth={bodyWidth}
      >
        <div className="all_correct"> 全問正解 </div>
        <div className="congrats"> おめでとうございます！</div>
        <div className="items_remain">
          <span className="items_text"> 賞品残り </span>
          <span className="number_text"> {totalPrizes} </span>
          <span className="items_text"> 品 </span>
        </div>
        <section className="prizeSection">
          <div className="prizeSlide">
            <button
              onClick={() => {
                if (sound.enabled) {
                  resetAudio(selectAudioRef);
                  setSelectAudio(true);
                }
                goToPrevPicture();
              }}
              className={`navButton previous`}
            >
              <img src={imgPath('previous.webp')} alt="previous icon" className="previous" />
            </button>
            <Swiper
              ref={swiperRef}
              loop={true}
              slidesPerView={slidesToShow}
              centeredSlides={true}
              className="prizeSlide"
              spaceBetween={30}
              onSlideChange={(swiper) => {
                setCurrentIndex(swiper.realIndex);
              }}
              initialSlide={currentIndex}
            >
              {Object.entries(PrizeDetails).map(([key, value], index) => (
                <SwiperSlide key={key} onClick={() => handleImageClick(key, index)}>
                  <div className="prizeContent">
                    <div className="prize_topText">
                      <div>{value.topTextPart1}</div>
                      <div>{value.topTextPart2}</div>
                    </div>
                    <div className="imageContainer">
                      <img src={value.src} alt={`${key}`} className="prize_details" />
                      {selectedImageIndex === index && (
                        <img src={imgPath('green_circle.webp')} alt="selected" className="greenCircle" />
                      )}
                    </div>
                    <div className="prize_botText">
                      <div>{value.bottomTextPart1}</div>
                      <div>{value.bottomTextPart2}</div>
                      <div>{value.bottomTextPart3}</div>
                      <div>{value.bottomTextPart4}</div>
                      <div>{value.bottomTextPart5}</div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <button
              onClick={() => {
                if (sound.enabled) {
                  resetAudio(selectAudioRef);
                  setSelectAudio(true);
                }
                goToNextPicture();
              }}
              className={`navButton next`}
            >
              <img src={imgPath('next.webp')} alt="next icon" className="next" />
            </button>
          </div>
        </section>
        {warningMessage && <div className="warningMessage">{warningMessage}</div>}
        <div className="buttonWrapper">
          <button
            onClick={() => {
              if (selectedImageIndex === null) {
                setWarningMessage('賞品を選択してください。');
                return;
              }
              handleClick();
            }}
            className={`confirm_button ${selectedImageIndex === null ? 'notAvailable' : ''} ${
              warningMessage ? 'warning' : ''
            }`}
          >
            <span className={`confirm_prize ${selectedImageIndex === null ? 'notAvailable' : ''}`}>賞品を確定する</span>
          </button>
        </div>
      </Wrapper>
    </QuizWrapper>
  );
};
