import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db, functions } from '../../../zz_general/utils/configs/firebase';
import { consoleError, consoleLog } from '../../../zz_general/utils/snippets/console';
import { httpsCallable } from 'firebase/functions';
import { isProd } from '../../../zz_general/utils/snippets/env';
import { BRv2_line_login } from '../../../zz_general/utils/functions';

export const loginWithTestUser = async () => {
  const docRef = doc(db, 'quiz', 'quiz-test', 'users', 'Ueac0667ea3866f8bb7a220c58201a6f2');
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    consoleLog("logged in with TestUser's data: ", docSnap.data());
    return docSnap.data();
  } else {
    consoleError('TestUser does not exist in FireStore'); // Error handling
    return null;
  }
};

// MEMO: Function to fetch LINE user data and validate token
export const loginWithLineUser = async (query) => {
  let result;

  try {
    const res = await BRv2_line_login(query.get('code'), encodeURI(`${window.location.origin}/quiz/start`));
    if (!res.success) {
      consoleError('Error logging in: ', res.error);
      return null;
    }
    result = res.result;
  } catch (error) {
    consoleError('Error logging in: ', error);
    return null;
  }

  // MEMO: log in with LINE user data
  try {
    const docRef = doc(db, 'quiz', isProd ? 'quiz' : 'quiz-test', 'users', result.sub);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      consoleLog('user:', docSnap.data());
      return docSnap.data(); // Set user state with data from Firebase
    } else {
      const newUser = {
        userInfo: result,
        challengeCount: 1, // Initialize retry count for new users
        tweetVerified: false, // Initialize tweet verification status for new users
      };
      consoleLog('new user:', newUser);
      await setDoc(docRef, newUser); // Save new user to Firebase
      return newUser; // Set user state with new user data
    }
  } catch (error) {
    consoleError('Error fetching user data: ', error);
    return null;
  }
};


