import { getDocs, collection } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';

import { db } from '../../zz_general/utils/configs/firebase';

export const getAddKamaitachiProduct = async () => {
  return await getDocs(collection(db, 'kamaitachi', 'tachibana-collabo', 'items'));
};
export const getLpProduct = async () => {
  return await getDocs(collection(db, 'items'));
};
