import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { RecoilRoot, useRecoilTransactionObserver_UNSTABLE } from 'recoil';
import ScrollToTop from './lp-legacy/utils/ScrollToTop';

import Home from './lp-legacy/home/index';
import { InputAccountInfo } from './lp-legacy/InputAccountInfo';
import { DeliveryInfo } from './lp-legacy/DeliveryInfo';
import { AddressInfo } from './lp-legacy/AddressInfo';
import { AddressEdit } from './lp-legacy/AddressEdit';
import Payment from './lp-legacy/Payment';
import { Confirm } from './lp-legacy/Confirm';
import Thanks from './lp-legacy/Thanks';

import { Menu } from './lp-legacy/Menu';
import { Maintenance } from './lp-legacy/maintenance';
import { TransactionAct } from './lp-legacy/TransactionAct';
import { Policy } from './lp-legacy/Policy';
import { NeroHowtoeatCard } from './lp-legacy/NeroHowtoeatCard';
import { BiancoHowtoeatCard } from './lp-legacy/BiancoHowtoeatCard';

import { Management } from './management/Management';

import { Secret } from './secret/secret';

import { Akigawa } from './shop/akigawa';
import { Yoyogi } from './shop/yoyogi';

import KamaitachiTop from './add-kamaitachi-customer/top';
import { KamaitachiContact } from './add-kamaitachi-customer/contact/index';

import { KamaitachiCustomerInfoDelivery } from './add-kamaitachi-customer/delivery/customer-info/index';
import { KamaitachiPaymentDelivery } from './add-kamaitachi-customer/delivery/payment/index';
import { KamaitachiDeliveryInfo } from './add-kamaitachi-customer/delivery/delivery-info/index';
import { KamaitachiAddressInfo } from './add-kamaitachi-customer/delivery/address-info/index';
import { KamaitachiConfirmDelivery } from './add-kamaitachi-customer/delivery/confirm';
import { KamaitachiThanksDelivery } from './add-kamaitachi-customer/delivery/thanks/index';

import { KamaitachiCustomerInfoTakeout } from './add-kamaitachi-customer/takeout/customer-info/index';
import { KamaitachiPaymentTakeout } from './add-kamaitachi-customer/takeout/payment/index';
import { KamaitachiConfirmTakeout } from './add-kamaitachi-customer/takeout/confirm';
import { KamaitachiThanksTakeout } from './add-kamaitachi-customer/takeout/thanks/index';
import { KamaitachiManagement } from './kamaitachi-management/index';

import { LPInquiry } from './lp/inquiry/index';

import { LPCustomerInfoDelivery } from './lp/delivery/customer-info/index';
import { LPPaymentDelivery } from './lp/delivery/payment/index';
import { LPDeliveryInfo } from './lp/delivery/delivery-info/index';
import { LPAddressInfo } from './lp/delivery/address-info/index';
import { LPConfirmDelivery } from './lp/delivery/confirm';
import { LPThanksDelivery } from './lp/delivery/thanks/index';

import { LPCustomerInfoTakeout } from './lp/takeout/customer-info/index';
import { LPPaymentTakeout } from './lp/takeout/payment/index';
import { LPConfirmTakeout } from './lp/takeout/confirm';
import { LPThanksTakeout } from './lp/takeout/thanks/index';
import { LPHome } from './lp/home';

import { AdminHome } from './management-beta/home';
import { AdminSignIn } from './management-beta/signIn';
import { AdminShipping } from './management-beta/shipping';
import { AdminRefund } from './management-beta/refund';

import { QuizExplanation } from './quiz/explanation';
import { QuizIntro } from './quiz/intro';
import { QuizStart } from './quiz/start';
import { QuizQuestions } from './quiz/questions';
import { QuizFailPrize } from './quiz/failPrize';
import { QuizRechallenge } from './quiz/rechallenge';
import { QuizSuccessPrize } from './quiz/successPrize';
import { QuizCongrats } from './quiz/congrats';
import { QuizPrizeDetail } from './quiz/prizeDetail';
import { QuizCodeInput } from './quiz/codeInput';
import { AdminCollabo } from './management-beta/collabo';

const stripePromise =
  process.env.REACT_APP_IS_PROD === 'true'
    ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY.toString())
    : loadStripe(process.env.REACT_APP_STRIPE_TEST_API_KEY.toString());

function App() {
  return (
    <RecoilRoot>
      {/* MEMO : 以下のコメントアウトを外すと、RecoilStateの内容をログに出力できます。通常コメントアウトしておいてください。 */}
      {/* <LogRecoilState /> */}
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* LP-旧版 */}
            {process.env.REACT_APP_IS_PROD === 'true' || (
              <>
                <Route path="/lp-legacy" element={<Home />} />
                <Route path="/lp-legacy/customer" element={<InputAccountInfo />} />
                <Route path="/lp-legacy/delivery" element={<DeliveryInfo />} />
                <Route path="/lp-legacy/address" element={<AddressInfo />} />
                <Route path="/lp-legacy/address-edit" element={<AddressEdit />} />
                <Route path="/lp-legacy/payment" element={<Payment />} />
                <Route path="/lp-legacy/confirm" element={<Confirm />} />
                <Route path="/lp-legacy/thanks" element={<Thanks />} />
              </>
            )}

            {/* LP-旧版 管理画面*/}
            <Route path="/management" element={<Management />} />

            {/* その他ページ */}
            <Route path="/menu" element={<Menu />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/transaction-act" element={<TransactionAct />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/nero-howtoeat-card" element={<NeroHowtoeatCard />} />
            <Route path="/bianco-howtoeat-card" element={<BiancoHowtoeatCard />} />

            {/* 謎解きページ */}
            <Route path="/secret" element={<Secret />} />

            {/* 店舗ページ */}
            <Route path="/akigawa" element={<Akigawa />} />
            <Route path="/yoyogi" element={<Yoyogi />} />

            {/* かまいたち 管理画面 */}
            <Route path="/kamaitachi/management" element={<KamaitachiManagement />} />

            {/* かまいたち追加予約 */}
            <Route path="/tachibana-ran-collabo" element={<KamaitachiTop />} />
            <Route path="/tachibana-ran-collabo/contact" element={<KamaitachiContact />} />

            {/* MEMO : 追加予約 配送ユーザー用ページ */}
            <Route path="/tachibana-ran-collabo/delivery/customer-info" element={<KamaitachiCustomerInfoDelivery />} />
            <Route path="/tachibana-ran-collabo/delivery/delivery-info" element={<KamaitachiDeliveryInfo />} />
            <Route path="/tachibana-ran-collabo/delivery/address-info" element={<KamaitachiAddressInfo />} />
            <Route path="/tachibana-ran-collabo/delivery/payment" element={<KamaitachiPaymentDelivery />} />
            <Route path="/tachibana-ran-collabo/delivery/confirm" element={<KamaitachiConfirmDelivery />} />
            <Route path="/tachibana-ran-collabo/delivery/customer-page" element={<KamaitachiThanksDelivery />} />

            {/* MEMO : 追加予約 受取ユーザー用ページ */}
            <Route path="/tachibana-ran-collabo/takeout/customer-info" element={<KamaitachiCustomerInfoTakeout />} />
            <Route path="/tachibana-ran-collabo/takeout/payment" element={<KamaitachiPaymentTakeout />} />
            <Route path="/tachibana-ran-collabo/takeout/confirm" element={<KamaitachiConfirmTakeout />} />
            <Route path="/tachibana-ran-collabo/takeout/customer-page" element={<KamaitachiThanksTakeout />} />

            {/* MEMO : LP-beta版 */}
            <Route path="/*" element={<LPHome />} />
            <Route path="/inquiry" element={<LPInquiry />} />

            {/* MEMO : LP-beta版 配送ユーザー用ページ */}
            <Route path="/delivery/customer-info" element={<LPCustomerInfoDelivery />} />
            <Route path="/delivery/delivery-info" element={<LPDeliveryInfo />} />
            <Route path="/delivery/address-info" element={<LPAddressInfo />} />
            <Route path="/delivery/payment" element={<LPPaymentDelivery />} />
            <Route path="/delivery/confirm" element={<LPConfirmDelivery />} />
            <Route path="/delivery/customer-page" element={<LPThanksDelivery />} />

            {/* MEMO : LP-beta版 受取ユーザー用ページ */}
            <Route path="/takeout/customer-info" element={<LPCustomerInfoTakeout />} />
            <Route path="/takeout/payment" element={<LPPaymentTakeout />} />
            <Route path="/takeout/confirm" element={<LPConfirmTakeout />} />
            <Route path="/takeout/customer-page" element={<LPThanksTakeout />} />

            {/* MEMO : LP-beta版 管理画面*/}
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/admin/sign-in" element={<AdminSignIn />} />
            <Route path="/admin/shipping" element={<AdminShipping />} />
            <Route path="/admin/refund" element={<AdminRefund />} />
            <Route path="/admin/collabo" element={<AdminCollabo />} />

            {/* クイズページ */}
            <Route path="/quiz" element={<QuizIntro />} />
            <Route path="/quiz/prize-detail" element={<QuizPrizeDetail />} />
            <Route path="/quiz/explanation" element={<QuizExplanation />} />
            <Route path="/quiz/start" element={<QuizStart />} />
            <Route path="/quiz/questions" element={<QuizQuestions />} />
            <Route path="/quiz/fail-prize" element={<QuizFailPrize />} />
            <Route path="/quiz/rechallenge" element={<QuizRechallenge />} />
            <Route path="/quiz/success-prize" element={<QuizSuccessPrize />} />
            <Route path="/quiz/congrats" element={<QuizCongrats />} />
            <Route path="/quiz/code-input" element={<QuizCodeInput />} />
          </Routes>
        </BrowserRouter>
      </Elements>
    </RecoilRoot>
  );
}

const LogRecoilState = () => {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    // eslint-disable-next-line no-console
    console.debug(
      '%c###### recoil state updated ######',
      'color: white; background-color: #97C5B0; padding: 4px; border-radius: 4px;'
    );
    snapshot.getNodes_UNSTABLE({ isModified: true }).forEach((node) => {
      // eslint-disable-next-line no-console
      console.debug(node.key, snapshot.getLoadable(node).contents);
    });
  });
};

export default App;
