import { useEffect, useState } from 'react';
import { db, functions } from '../../zz_general/utils/configs/firebase';
import { collection, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Wrapper, Modal } from './scss'; // Import styled components
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export const AdminRefund = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [reservations, setReservations] = useState([]);
  const [showPage, setShowPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [modalData, setModalData] = useState(null); // Data for the modal
  const [refundAmount, setRefundAmount] = useState(0); // Refund amount input

  // Fetch reservations from Firestore
  const fetchReservations = async () => {
    try {
      const reservationsCollection = collection(db, 'LP/LP-test/reservations'); // Collection path
      const reservationsSnapshot = await getDocs(reservationsCollection);

      const reservationsList = reservationsSnapshot.docs.map((doc) => {
        const data = doc.data();
        let reserveDateFormatted = 'N/A'; // Default value if reserveDate is not valid
        let reserveDateTimestamp = null; // Initialize a timestamp for sorting

        // Check if reserveDate exists and is a valid Firestore Timestamp
        if (data.reserveDate && typeof data.reserveDate.toDate === 'function') {
          const date = data.reserveDate.toDate();
          reserveDateFormatted = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
            .getDate()
            .toString()
            .padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
          reserveDateTimestamp = date.getTime(); // Get the timestamp for sorting
        }

        // Format purchased items
        const purchasedItems =
          data.items && typeof data.items === 'object'
            ? Object.keys(data.items)
                .filter((uid) => data.items[uid] && Number(data.items[uid].count) >= 1) // Filter valid items
                .map(
                  (uid) => `${data.items[uid].name} x ${data.items[uid].count}` // Format as "name x count"
                )
            : [];

        // Calculate the total refunded amount
        const totalRefunded = (data.merchantRefundDetails || []).reduce((sum, refund) => sum + (refund.amount || 0), 0);

        // Calculate the remaining price
        const remainingPrice = (data.total?.totalPrice || 0) - totalRefunded;

        return {
          id: doc.id,
          reserveDate: reserveDateFormatted, // Formatted reserve date
          reserveDateTimestamp, // Raw timestamp for sorting
          fName: data.fName,
          lName: data.lName,
          fNameKana: data.fNameKana,
          lNameKana: data.lNameKana,
          deliveryDate: data.howToGet?.receiveMethod?.delivery?.date,
          deliveryTime: data.howToGet?.receiveMethod?.delivery?.time,
          emailAddress: data.emailAddress,
          paymentMethod: data.paymentMethod,
          paymentIntentId: data.paymentIntentId,
          totalPrice: data.total?.totalPrice,
          remainingPrice: remainingPrice > 0 ? remainingPrice : 0, // Ensure no negative values
          salesOperation: data.howToGet?.flag === 'delivery' ? 'EC' : 'take-out',
          purchasedItems, // Keep purchased items as an array
          paymentStatus: data.paymentStatus || 'N/A', // Add payment status
          shippingStatus: data.shippingStatus || 'N/A', // Add shipping status
        };
      });

      // Sort the reservations by reserveDateTimestamp in descending order
      reservationsList.sort((a, b) => b.reserveDateTimestamp - a.reserveDateTimestamp);

      setReservations(reservationsList);
    } catch (err) {
      console.error('Error fetching reservations:', err);
      setError('Error fetching reservations');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReservations();
  }, []);

  // Format price with commas and yen symbol
  const formatPrice = (price) => {
    // Explicitly check for 0 as a valid value and format it properly
    if (price === 0) return '¥0';
    return price ? `¥${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 'N/A';
  };

  const openModal = (reservation, title) => {
    setModalData({ ...reservation, modalTitle: title }); // Include title in modalData
    setRefundAmount(0); // Set default refund amount
  };

  const handleRefund = () => {
    if (!modalData) return;

    const { paymentMethod } = modalData;

    // Call refund function based on payment method
    if (paymentMethod === 'payPay') {
      refundPayPay(); // Call your existing handleCancel for PayPay
    } else if (paymentMethod === 'stripe') {
      refundStripe(); // Call the refundStripe function for Stripe
    }
  };

  const closeModal = () => {
    setModalData(null);
    setRefundAmount(''); // Clear the refund amount input
  };

  const refundPayPay = async () => {
    if (!modalData) return;

    const {
      id,
      remainingPrice,
      totalPrice,
      fName,
      lName,
      fNameKana,
      lNameKana,
      deliveryDate,
      deliveryTime,
      emailAddress,
      paymentMethod,
      purchasedItems,
    } = modalData;
    const merchantRefundId = `refund-${id}-${Date.now()}`; // Unique refund ID
    const amount = parseFloat(refundAmount);
    const isStatusChangeChecked = document.getElementById('statusChange').checked; // Check checkbox status

    if (isNaN(amount) || amount <= 0 || amount > remainingPrice) {
      alert('Please enter a valid refund amount');
      return;
    }

    setLoading(true);

    try {
      const confirmPaymentUrl = 'http://127.0.0.1:5001/bene-reglo-lp/asia-northeast1/confirmPaypayPaymentStatus';
      const confirmPaymentResponse = await fetch(confirmPaymentUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ merchantPaymentId: id }),
      });
      if (!confirmPaymentResponse.ok) {
        throw new Error(`Confirm payment HTTP error! Status: ${confirmPaymentResponse.status}`);
      }

      const response = await confirmPaymentResponse.json();
      console.log('confirmPaymentResponse is: ', response);

      const paymentId = response.result?.data?.paymentId;
      if (!paymentId) throw new Error('PaymentId not found');

      const refundPayload = {
        data: {
          merchantRefundId,
          paymentId,
          amount,
        },
      };
      console.log('merchantRefundId is: ', merchantRefundId);

      const refundPayment = 'http://127.0.0.1:5001/bene-reglo-lp/asia-northeast1/createPaypayRefund';
      const refundPaymentResponse = await fetch(refundPayment, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(refundPayload),
      });
      if (!refundPaymentResponse.ok) {
        throw new Error(`Refund payment HTTP error! Status: ${refundPaymentResponse.status}`);
      }

      const refundResponse = await refundPaymentResponse.json();
      console.log('refundPaymentResponse is: ', refundResponse);

      if (refundResponse.result?.resultInfo?.code === 'SUCCESS') {
        alert('Refund successful');

        // Extract timestamp from merchantRefundId and convert it to JST
        const extractedTimestamp = parseInt(merchantRefundId.split('-')[2], 10);
        const createdAt = new Date(extractedTimestamp).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });

        const reservationDocRef = doc(db, 'LP/LP-test/reservations', id); // Firestore document reference

        // Calculate new remaining price
        const newRemainingPrice = remainingPrice - amount;

        // Determine the new payment status
        let newPaymentStatus = 'Partially Refunded';
        if (newRemainingPrice === 0) {
          newPaymentStatus = 'Fully Refunded';
        }

        const updateData = {
          merchantRefundDetails: arrayUnion({ merchantRefundId, amount, createdAt }), // Append refund details
          paymentStatus: newPaymentStatus, // Update payment status
        };

        // If checkbox is checked, add shippingStatus update
        if (isStatusChangeChecked) {
          updateData.shippingStatus = 'canceled';
        }

        // Update Firestore document
        await updateDoc(reservationDocRef, updateData);

        // Send refund email to the customer
        const emailUrl = 'http://127.0.0.1:5001/bene-reglo-lp/asia-northeast1/sendRefundEmail';
        const emailPayload = {
          fName,
          lName,
          fNameKana,
          lNameKana,
          deliveryDate,
          deliveryTime,
          emailAddress,
          paymentMethod,
          totalPrice,
          reserveDate: modalData.reserveDate,
          items: purchasedItems,
          refundAmount: amount,
          newRemainingPrice,
        };

        try {
          // Call the email cloud function
          const emailResponse = await fetch(emailUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailPayload),
          });

          if (!emailResponse.ok) {
            throw new Error(`Email HTTP error! Status: ${emailResponse.status}`);
          }

          console.log('Refund email sent successfully');
        } catch (emailError) {
          console.error('Error sending refund email:', emailError.message);
        }

        // Refresh reservations to reflect changes
        await fetchReservations();

        setModalData(null); // Close the modal
      } else {
        alert('Refund failed: ' + (refundResponse.resultInfo?.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Refund error:', error);
      alert('Error processing refund: ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  const refundStripe = async () => {
    if (!modalData) return;

    const {
      id,
      paymentIntentId,
      paymentMethod,
      remainingPrice,
      totalPrice,
      fName,
      lName,
      fNameKana,
      lNameKana,
      deliveryDate,
      deliveryTime,
      emailAddress,
      purchasedItems,
    } = modalData;

    const amount = parseFloat(refundAmount);
    const isStatusChangeChecked = document.getElementById('statusChange').checked; // Check checkbox status

    if (isNaN(amount) || amount <= 0 || amount > remainingPrice) {
      alert('Please enter a valid refund amount');
      return;
    }

    setLoading(true);

    try {
      // URL for the cloud function
      const refundUrl = 'http://127.0.0.1:5001/bene-reglo-lp/asia-northeast1/createStripeRefund';

      // Payload for the refund
      const refundPayload = {
        paymentIntentId: paymentIntentId,
        amount,
      };
      console.log('payment intent id is: ', paymentIntentId);

      // Call the refund cloud function
      const refundResponse = await fetch(refundUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(refundPayload),
      });

      if (!refundResponse.ok) {
        throw new Error(`Refund HTTP error! Status: ${refundResponse.status}`);
      }

      const result = await refundResponse.json();
      console.log('refund response', result);

      // Check if refund status is "succeeded"
      if (result.status === 'succeeded') {
        alert('Refund successful');

        // Extract timestamp from server response
        const createdAt = new Date(result.created * 1000).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });

        const reservationDocRef = doc(db, 'LP/LP-test/reservations', id);

        // Calculate new remaining price
        const newRemainingPrice = remainingPrice - amount;

        // Determine the new payment status
        let newPaymentStatus = 'Partially Refunded';
        if (newRemainingPrice === 0) {
          newPaymentStatus = 'Fully Refunded';
        }

        const updateData = {
          merchantRefundDetails: arrayUnion({ stripeRefundId: result.id, amount, createdAt }),
          paymentStatus: newPaymentStatus,
        };

        // If checkbox is checked, add shippingStatus update
        if (isStatusChangeChecked) {
          updateData.shippingStatus = 'canceled';
        }

        await updateDoc(reservationDocRef, updateData);

        // URL for the send email cloud function
        const emailUrl = 'http://127.0.0.1:5001/bene-reglo-lp/asia-northeast1/sendRefundEmail';

        // Payload for the email
        const emailPayload = {
          fName,
          lName,
          fNameKana,
          lNameKana,
          emailAddress,
          refundAmount: amount,
          newRemainingPrice,
          items: purchasedItems,
          deliveryDate,
          deliveryTime,
          paymentMethod,
          totalPrice,
          reserveDate: modalData.reserveDate,
        };

        try {
          // Call the email cloud function
          const emailResponse = await fetch(emailUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailPayload),
          });

          if (!emailResponse.ok) {
            throw new Error(`Email HTTP error! Status: ${emailResponse.status}`);
          }

          console.log('Refund email sent successfully');
        } catch (emailError) {
          console.error('Error sending refund email:', emailError.message);
        }

        // Refresh reservations to reflect changes
        await fetchReservations();

        setModalData(null); // Close the modal
      } else {
        alert('Refund failed: ' + (result.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Refund error:', error);
      alert('Error processing refund: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReservations();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid !== process.env.REACT_APP_ADMIN_UID) {
          navigate('/admin/sign-in');
        }
      } else {
        navigate('/admin/sign-in');
      }
      setShowPage(true);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!showPage) return <></>;

  return (
    <Wrapper>
      <h2>Reservations Table</h2>
      <table>
        <thead>
          <tr>
            <th>注文ID</th>
            <th>注文日時</th>
            <th>名前</th>
            <th>メールアドレス</th>
            <th>支払い方法</th>
            <th>合計金額</th>
            <th>残額</th>
            <th>販売種類</th>
            <th>支払い状況</th> {/* Payment Status */}
            <th>配送状況</th> {/* Shipping Status */}
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {reservations.map((reservation) => (
            <tr key={reservation.id}>
              <td>{reservation.id}</td>
              <td>{reservation.reserveDate}</td>
              <td>{`${reservation.fName} ${reservation.lName}`}</td>
              <td>{reservation.emailAddress}</td>
              <td>{reservation.paymentMethod || 'N/A'}</td>
              <td>{formatPrice(reservation.totalPrice)}</td>
              <td>{formatPrice(reservation.remainingPrice)}</td>
              <td>{reservation.salesOperation}</td>
              <td>{reservation.paymentStatus}</td> {/* Payment Status */}
              <td>{reservation.shippingStatus}</td> {/* Shipping Status */}
              <td>
                {reservation.paymentMethod === 'payPay' && (
                  <button
                    className="cancel-button"
                    onClick={() => openModal(reservation, 'PayPay返金')} // Pass title
                    disabled={reservation.paymentStatus === 'Fully Refunded'}
                  >
                    PayPay返金
                  </button>
                )}
                {reservation.paymentMethod === 'stripe' && (
                  <button
                    className="cancel-button"
                    onClick={() => openModal(reservation, 'Stripe返金')} // Pass title
                    disabled={reservation.paymentStatus === 'Fully Refunded'}
                  >
                    Stripe返金
                  </button>
                )}
                {reservation.paymentMethod === 'N/A' && null} {/* Hide button */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {modalData && (
        <Modal>
          <div className="modal-content">
            <h3>{modalData.modalTitle}</h3> {/* Display dynamic title */}
            <p>注文ID: {modalData.id}</p>
            <p>注文日時: {modalData.reserveDate}</p>
            <p>氏名: {`${modalData.fName} ${modalData.lName}`}</p>
            <p>販売種類: {modalData.salesOperation}</p>
            <p>購入商品:</p>
            <div>
              {modalData.purchasedItems.map((item, index) => (
                <div key={index}>{item}</div> // Display each item in a separate div
              ))}
            </div>
            <p>合計金額: {formatPrice(modalData.totalPrice)}</p>
            <p>残額: {formatPrice(modalData.remainingPrice)}</p>
            <div className="refund-amount-row">
              <label>返金金額: </label>
              <span>¥</span>
              <input
                type="number"
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
                step="0.01"
                min="0"
                max={modalData.remainingPrice}
              />
            </div>
            <div className="checkbox-row">
              <input type="checkbox" id="statusChange" name="statusChange" />
              <label htmlFor="statusChange">ステータスをキャンセルに変更する</label>
            </div>
            <div className="modal-actions">
              <button onClick={closeModal}>Close</button>
              <button onClick={handleRefund} disabled={loading}>
                返金確定
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};
