import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.module.scss';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    // <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    // </React.StrictMode>
  );
} else {
  // eslint-disable-next-line no-console
  console.error('Root element not found');
}
