import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import css from './index.module.scss';

import { importAll } from '../../utils/image';
import { CustomerInfo_takeout, isPastReservationsDate, KamaitachiHeader, OrderDetails } from '../../component';
import QRCode from 'react-qr-code';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addKamaitachiProductState, addKamaitachiReservationIdState, addKamaitachiTotalState } from '../../store/atoms';
import { termsAndPolicyState } from '../../../lp-legacy/atoms';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../../zz_general/utils/configs/firebase';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { SideMenu } from '../../component/index';
import { LinkButton } from '../../components/linkButton';
import { FormHeader } from '../../components/fourStepBar';
import { isProd } from '../../../zz_general/utils/snippets/env';

export const KamaitachiThanksTakeout = () => {
  const image = importAll(require.context('./image', false, /\.(webp|avif|svg)$/));
  const setProduct = useSetRecoilState(addKamaitachiProductState);
  const setKamaitachiTotal = useSetRecoilState(addKamaitachiTotalState);

  // MEMO: URLパラメータから予約IDを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const reservationId = query.get('reservationsId');

  const recoilReservationId = useRecoilValue(addKamaitachiReservationIdState);
  const inputFormWidth = [340, 512, 720];
  const linkButtonHeight = [48, 52, 60];
  const [reservationData, setReservationData] = useState({});
  const [orderDetails, setOrderDetails] = useState('');
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState('');

  const navigate = useNavigate();
  const handleLinkClick = () => {
    setProduct({});
    setKamaitachiTotal({});
    navigate('/tachibana-ran-collabo');
  };

  // MEMO : 決済の完了が確認できるまでローディングを表示
  useEffect(() => {
    if (paymentStatus === 'completed') {
      setLoading(false);
    }
  }, [paymentStatus]);

  // MEMO: SendGridを発火しメールを送信
  const igniteSendGrid = async (data) => {
    const BRv2_delivery_sendEmail = async (docId) => {
      const url = isProd
        ? 'https://asia-northeast1-bene-reglo-lp.cloudfunctions.net/'
        : 'http://127.0.0.1:5001/bene-reglo-lp/asia-northeast1/';
      const endpoint = url + 'BRv2_kamaitachiTakeout-sendEmail';
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            docId: docId,
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return { success: false, error: error.message };
      }
    };
    BRv2_delivery_sendEmail(data.reservationsId)
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('メール送信しました。');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending email:', error);
      });
  };

  // MEMO: ある商品の予約総数を更新
  const updateReserveTotal = async (itemId, num) => {
    const collectionRef = collection(
      db,
      'kamaitachi',
      process.env.REACT_APP_IS_PROD === 'true' ? 'tachibana-collabo' : 'tachibana-collabo-test',
      'admins'
    );
    const docRef = doc(collectionRef, itemId);
    const fetchReserveTotal = async () => {
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    };
    const promise = fetchReserveTotal();
    promise
      .then((response) => {
        setDoc(docRef, {
          reserveTotal: response.reserveTotal + num,
        });
      })
      .catch(() => {});
  };

  // MEMO: 商品リストから予約総数を更新
  const loopUpdateReserveTotal = async (items) => {
    for (let id in items) {
      items[id].count > 0 && updateReserveTotal(id, items[id].count);
    }
  };

  // MEMO: PayPay決済のステータスを取得し更新
  const fetchPaymentStatus = async () => {
    try {
      const BRv2_paypay_confirmPaymentStatus = async (stripePayload) => {
        const url = isProd
          ? 'https://asia-northeast1-bene-reglo-lp.cloudfunctions.net/'
          : 'http://127.0.0.1:5001/bene-reglo-lp/asia-northeast1/';
        const endpoint = url + 'BRv2_paypay-confirmPaymentStatus';
        try {
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              merchantPaymentId: stripePayload,
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          return data;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error:', error);
          return { success: false, error: error };
        }
      };
      const confirmPaymentStatusRes = await BRv2_paypay_confirmPaymentStatus(reservationId);

      console.log('confirmPaymentStatusRes: ', confirmPaymentStatusRes);
      // MEMO: error handling for confirmPaymentStatus
      if (confirmPaymentStatusRes.result.data.status === 'COMPLETED') {
        // eslint-disable-next-line no-console
        console.log('paypay: completed');
        return true;
      } else {
        // eslint-disable-next-line no-console
        console.log('paypay: ', confirmPaymentStatusRes.result.data.status);
        // MEMO: 決済未完了時の処理
        return false;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('catch error: ', error);
      return false;
    }
  };

  const UpdateReservationAvailability = async (customer) => {
    if (!customer.UpdateReservationAvailability) {
      return;
    }
    const time = customer.UpdateReservationAvailability;
    const docRef = doc(db, 'kamaitachi', 'tachibana-collabo');
    const docSnap = await getDoc(docRef);
    await updateDoc(docRef, {
      reservationAvailability: {
        ...docSnap.data().reservationAvailability,
        [time]: docSnap.data().reservationAvailability[time] - 1,
      },
    });
  };

  // MEMO: 予約情報を取得
  useEffect(() => {
    !reservationId &&
      window.location.replace(
        process.env.REACT_APP_IS_PROD === 'true'
          ? 'https://bene-regalo.com/tachibana-ran-collabo'
          : 'http://localhost:3000/tachibana-ran-collabo'
      );
    const collectionRef = collection(
      db,
      'kamaitachi',
      process.env.REACT_APP_IS_PROD === 'true' ? 'tachibana-collabo' : 'tachibana-collabo-test',
      'reservations'
    );
    const docRef = doc(collectionRef, reservationId);
    const fetchReservationData = async () => {
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    };
    const promise = fetchReservationData();
    promise
      .then((response) => {
        setPaymentStatus(response.paymentStatus);
        setReservationData(response);
        // MEMO : 決済が完了していない場合の処理
        if (response.paymentStatus === 'pending') {
          // MEMO : Stripeの場合thanks遷移で完了しているはずなのでここで更新
          if (response.paymentMethod === 'stripe') {
            updateDoc(docRef, {
              paymentStatus: 'completed',
              isMailSent: true,
            });
            setPaymentStatus('completed');
            igniteSendGrid(response);
            loopUpdateReserveTotal(response.items);
          }
          // MEMO : PayPayの場合
          if (response.paymentMethod === 'payPay') {
            const result = fetchPaymentStatus();
            if (result) {
              updateDoc(docRef, {
                paymentStatus: 'completed',
                mailStatus: 'sent',
              });
              setPaymentStatus('completed');
              igniteSendGrid(response, docRef);
              loopUpdateReserveTotal(response.items);
            }
          }
          UpdateReservationAvailability(response);
        }
        // MEMO : OrderDetailsはローカルステートの処理も持つので初めにセットしておく
        setOrderDetails(<OrderDetails product={response.items} total={response.total} />);
      })
      .catch((error) => {
        console.error('Error fetching document:', error);
        isProd && navigate('/tachibana-ran-collabo');
      });
  }, []);

  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);
  const updateTermsAndPolicy = (boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['terms'] = boolean;
      return future;
    });
  };

  if (loading) {
    return (
      <div className={classNames(css.loadingContainer)}>
        <div className={classNames(css.loading)}></div>
        <div className={classNames(css.loadingText)}>Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className={classNames(css.kamaitachiBodyBase)}>
        <div className={classNames(css.reservations, css.kamaitachiBodyBase)}>
          {recoilReservationId === reservationId ? (
            // MEMO : thanksページのヘッダー
            <FormHeader title="予約完了" step={4} />
          ) : (
            // MEMO : 予約確認ページのヘッダー
            <>
              <SideMenu>
                <li>
                  <Link to={'/policy'}>
                    <p
                      onClick={() => {
                        updateTermsAndPolicy(true);
                      }}
                    >
                      利用規約
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to={'/policy'}>
                    <p
                      onClick={() => {
                        updateTermsAndPolicy(false);
                      }}
                    >
                      プライバシーポリシー&nbsp;&nbsp;&nbsp;
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to={'/transaction-act'}>
                    <p>特定商取引法に基づく表記</p>
                  </Link>
                </li>
                <li>
                  <Link to={'/tachibana-ran-collabo/contact'}>
                    <p>よくある質問／お問い合わせ</p>
                  </Link>
                </li>
              </SideMenu>
              <KamaitachiHeader />
            </>
          )}
          <div className={classNames(css.message)}>
            ご予約ありがとうございます。
            <br />
            こちらのQRが受け取りの際に必要ですので、
            <br />
            保存がおすすめです。
            <br />
          </div>
          <div className={css.reservationsIdSection}>
            <div className={css.reservationsId}>
              <div className={css.reservationsId}>予約番号: {reservationId}</div>
            </div>
            <div className={css.reservationQr}>
              <QRCode value={reservationId} style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
          <div className={css.waitingCover}>
            <img
              src={
                isPastReservationsDate(reservationData) ? image['waitingCover.webp'] : image['waitingCoverTakeout.webp']
              }
              alt=""
              className={css.waitingCoverImg}
            />
          </div>
          {/* 注文内容 */}
          <div className={css.reservationsContents}>
            {orderDetails}
            <hr className={classNames(css.lineM)} />
            <CustomerInfo_takeout
              customer={{
                firstName: reservationData.fName,
                lastName: reservationData.lName,
                firstNameKana: reservationData.fNameKana,
                lastNameKana: reservationData.lNameKana,
                mailAddress: reservationData.emailAddress,
                phoneNum: reservationData.phoneNumber,
              }}
              takeout={reservationData.howToGet.receiveMethod[reservationData.howToGet.flag]}
            />
            <hr className={classNames(css.lineM)} />
            <div className={css.title}>決済</div>
            {reservationData.paymentMethod === 'stripe' && (
              <div className={classNames(css.cardNum)}>
                <img src={image['credit_card.svg']} className={classNames(css.cardIcon)} />
                <span className={css.text}>
                  クレジットカード
                  <br />
                  <span className={classNames(css.cardFlex)}>
                    <img src={image[`${reservationData.creditBrand}.webp`]} className={classNames(css.cardBrand)} />
                    <span className={css.cardText}>****-****-****-{reservationData.creditNumber}</span>
                  </span>
                </span>
              </div>
            )}
            {reservationData.paymentMethod === 'payPay' && (
              <img src={image['pay_pay.webp']} className={classNames(css.payPay)} />
            )}
            <LinkButton
              className={classNames(css.linkButton)}
              width={inputFormWidth}
              height={linkButtonHeight}
              text="TOPへ"
              color="white"
              arrow="right"
              active={true}
              onClick={() => {
                handleLinkClick();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
