import { useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import classNames from 'classnames';

import css from './index.module.scss';

import { StyledDiv } from '../utils/styled.js';
import { validation } from '../utils/form.js';
import { importAll } from '../utils/image.js';

import { InputForm } from '../components/inputForm/inputForm.js';
import { LinkButton } from '../components/linkButton/index.js';
import { LPHeader, SideMenu } from '../components/index.js';

// MEMO: Recoil
import { useSetRecoilState } from 'recoil';
import { termsAndPolicyState } from '../../lp-legacy/atoms.js';

// MEMO: fireStore
import { contactCollectionCreate } from '../store/index.js';
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase.js';
import { isProd } from '../../zz_general/utils/snippets/env.js';
import { generateUniqueID } from '../../zz_general/utils/snippets/generateId.js';
import { BRv2_inquiry_sendEmail } from '../../zz_general/utils/functions/index.js';

export const LPInquiry = () => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const inputFormWidth = [340, 512, 720];
  const inputFormHalfWidth = [160, 246, 350];
  const inputFormHeight = [32, 38, 44];
  const linkButtonHeight = [48, 52, 60];
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [activePullDown1, setActivePullDown1] = useState(false);
  const [activePullDown2, setActivePullDown2] = useState(false);
  const [activePullDown3, setActivePullDown3] = useState(false);
  const [activePullDown4, setActivePullDown4] = useState(false);
  const [activePullDown5, setActivePullDown5] = useState(false);
  // const [activePullDown6, setActivePullDown6] = useState(false);
  // const [activePullDown7, setActivePullDown7] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [customer, setCustomer] = useState({});

  const defaultFormObj = {
    familyName: 'not filled',
    lastName: 'not filled',
    familyNameKana: 'not filled',
    lastNameKana: 'not filled',
    mailAddress: 'not filled',
    inquiryBody: 'not filled',
  };

  const [form, setForm] = useState(defaultFormObj);

  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);
  const setCustomerByProp = (key, value) => {
    setCustomer((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const setFormByProp = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const onChangeInputForm = (event, prop, validationType) => {
    const value = event.target.value;
    setCustomerByProp(prop, value);
    if (!value) {
      setFormByProp(prop, 'not filled');
      setIsValid(false);
    } else if (validation[validationType].test(value)) {
      setFormByProp(prop, 'valid');
    } else {
      setFormByProp(prop, 'invalid');
      setIsValid(false);
    }
  };
  /**enter入力で次のフォームへ移動 */
  const onKeyUpInputForm = (event, currentProp, nextProp) => {
    if (form[currentProp] === 'valid' && event.keyCode === 13) {
      document.getElementById(nextProp).focus();
    }
  };
  /**ふりがなの自動入力 */
  const fillKanaAutomatically = (event, prop) => {
    const value = event.target.value;
    if (validation.hiragana.test(value)) {
      setCustomerByProp(prop + 'Kana', value);
      setFormByProp(prop + 'Kana', 'valid');
    }
  };
  // MEMO : inputのtypeをemailにするとspace入力時にonClickが発火しないため、onKeyUpで代替する
  const onKeyUpMailAddress = (event) => {
    const value = event.target.value;

    // スペースが入力された場合
    if (event.keyCode === 32) {
      setFormByProp('mailAddress', 'invalid');
      setIsValid(false);
    }
    // フィールドが空の場合
    else if (!value) {
      setFormByProp('mailAddress', 'not filled');
      setIsValid(false);
    }
    // メールアドレスが無効な場合
    else if (!validation.mail.test(value)) {
      setFormByProp('mailAddress', 'invalid');
      setIsValid(false);
    }
    // メールアドレスが有効な場合
    else {
      setFormByProp('mailAddress', 'valid');
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (isValid) return;
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (!isValid) setButtonIsLoading(false);
  }, [isValid]);

  useLayoutEffect(() => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['backPage'] = '/inquiry';
      return future;
    });
  }, []);

  const updateTermsAndPolicy = (boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['terms'] = boolean;
      return future;
    });
  };
  const sendContact = async () => {
    if (!customer) return;
    setButtonIsLoading(true);
    let docId = '';
    try {
      const collectionRef = collection(db, 'LP', isProd ? 'LP' : 'LP-test', 'contact');
      const id = await generateUniqueID(collectionRef, 20);
      const docRef = doc(collectionRef, id);
      await setDoc(docRef, {
        ...customer,
        timestamp: new Date(),
      });
      // eslint-disable-next-line no-console
      console.log('Created document ID:', docRef.id);
      docId = docRef.id;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
      toast.error('送信に失敗しました');
      setButtonIsLoading(false);
      return;
    }

    toast.success('送信しました');

    await BRv2_inquiry_sendEmail(docId);

    setCustomer({});
    setForm(defaultFormObj);
    setIsButtonActive(false);
    setButtonIsLoading(false);
  };

  return (
    <>
      <Toaster position="top-right" />
      <SideMenu>
        <li>
          <Link to={'/'}>
            <p>TOP</p>
          </Link>
        </li>
        <li>
          <Link to={'/policy'}>
            <p
              onClick={() => {
                updateTermsAndPolicy(true);
              }}
            >
              利用規約
            </p>
          </Link>
        </li>
        <li>
          <Link to={'/policy'}>
            <p
              onClick={() => {
                updateTermsAndPolicy(false);
              }}
            >
              プライバシーポリシー&nbsp;&nbsp;&nbsp;
            </p>
          </Link>
        </li>
        <li>
          <Link to={'/transaction-act'}>
            <p>特定商取引法に基づく表記</p>
          </Link>
        </li>
      </SideMenu>
      <div className={classNames(css.LPBodyBase)}>
        <LPHeader />
        <StyledDiv
          className={classNames(css.contactForm)}
          responsive={{
            width: inputFormWidth,
          }}
        >
          <h1 className={classNames(css.H1Title)}>よくある質問</h1>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown1(!activePullDown1);
            }}
          >
            <div>1.&nbsp;&nbsp;&nbsp;&nbsp;キャンセルしたいです。</div>
            <div>{activePullDown1 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown1 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            ・お客様のご都合、当店指定外の保存方法での品質の劣化による返品・交換はご遠慮願います。
            <br />
            ・品質や取り扱いには十分注意しておりますが、万一不良品や異なる商品が届いた場合は、商品到着後2日以内にお電話またはメールでご連絡ください。必要に応じて、当店負担で交換いたします。返送の際は、クール便・着払いで早急にご返送いただけますようお願いいたします。
            <br />
            ・商品到着後3日を過ぎてからのご連絡については、返品・交換をお受けできませんので、あらかじめご了承ください。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown2(!activePullDown2);
            }}
          >
            <div>2.&nbsp;&nbsp;&nbsp;&nbsp;注文完了メールが届きません。注文はできていますか？ </div>
            <div>{activePullDown2 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown2 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            キャリアメール（au、docomo、SoftBank）をご利用の場合、迷惑メールとして分類されることがあります。
            <br />
            お手数ですが、迷惑メールフォルダや受信設定をご確認ください。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown3(!activePullDown3);
            }}
          >
            <div>3.&nbsp;&nbsp;&nbsp;&nbsp;購入個数に上限はありますか？</div>
            <div>{activePullDown3 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown3 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            商品ごとに購入数に制限を設ける場合があります。
            <br />
            合計の個数には制限はありませんが、食べきれる量をご注文ください。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown4(!activePullDown4);
            }}
          >
            <div>4.&nbsp;&nbsp;&nbsp;&nbsp;価格の表示されたものは梱包されていますか？</div>
            <div>{activePullDown4 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown4 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            請求書や納品書など、価格の表示されたものは商品に梱包されておりません。
            <br />
            ご希望があればお申し付けください。お付けいたします。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown5(!activePullDown5);
            }}
          >
            <div>5.&nbsp;&nbsp;&nbsp;&nbsp;支払い方法は何が使えますか？</div>
            <div>{activePullDown5 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown5 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            クレジットカード（Visa／Mastercard／JCB／American Express／Diners Club）および、PayPayがご利用いただけます。
            <br />
            代金引換での発送は、現状対応しておりません。ご不便をおかけしますが、よろしくお願いいたします。
          </div>
          <h1 className={classNames(css.H1Title)}>お問い合わせ</h1>
          <div className={classNames(css.formFlex)}>
            <InputForm
              required={true}
              title="お名前"
              help="姓名合わせて全角16文字まで"
              width={inputFormHalfWidth}
              height={inputFormHeight}
              placeholder="定良"
              description="姓"
              id="familyName"
              value={customer.familyName || ''}
              onChange={(e) => {
                onChangeInputForm(e, 'familyName', 'string');
                fillKanaAutomatically(e, 'familyName');
              }}
              onKeyUp={(e) => {
                onKeyUpInputForm(e, 'familyName', 'lastName');
              }}
              check={form.familyName === 'valid'}
              error={form.familyName === 'invalid'}
              errorMessage="姓に無効な文字や空白を含んでいます"
            />
            <StyledDiv
              responsive={{
                marginTop: [inputFormHeight[0] * 0.75, inputFormHeight[1] * 0.75, inputFormHeight[2] * 0.75],
              }}
            >
              <InputForm
                width={inputFormHalfWidth}
                height={inputFormHeight}
                placeholder="美鈴"
                description="名"
                id="lastName"
                value={customer.lastName || ''}
                onChange={(e) => {
                  onChangeInputForm(e, 'lastName', 'string');
                }}
                onKeyUp={(e) => {
                  onKeyUpInputForm(e, 'lastName', 'familyNameKana');
                  fillKanaAutomatically(e, 'lastName');
                }}
                check={form.lastName === 'valid'}
                error={form.lastName === 'invalid'}
                errorMessage="名に無効な文字や空白を含んでいます"
              />
            </StyledDiv>
          </div>
          <div className={classNames(css.formFlex)}>
            <InputForm
              required={true}
              title="ふりがな"
              help="せいめい合わせて全角25文字まで"
              width={inputFormHalfWidth}
              height={inputFormHeight}
              placeholder="ていら"
              description="せい"
              id="familyNameKana"
              value={customer.familyNameKana || ''}
              onChange={(e) => {
                onChangeInputForm(e, 'familyNameKana', 'hiragana');
              }}
              onKeyUp={(e) => {
                onKeyUpInputForm(e, 'familyNameKana', 'lastNameKana');
              }}
              check={form.familyNameKana === 'valid'}
              error={form.familyNameKana === 'invalid'}
              errorMessage="ひらがなで入力してください"
            />
            <StyledDiv
              responsive={{
                marginTop: [inputFormHeight[0] * 0.75, inputFormHeight[1] * 0.75, inputFormHeight[2] * 0.75],
              }}
            >
              <InputForm
                width={inputFormHalfWidth}
                height={inputFormHeight}
                placeholder="みすず"
                description="めい"
                id="lastNameKana"
                value={customer.lastNameKana || ''}
                onChange={(e) => {
                  onChangeInputForm(e, 'lastNameKana', 'hiragana');
                }}
                onKeyUp={(e) => {
                  onKeyUpInputForm(e, 'lastNameKana', 'mailAddress');
                }}
                check={form.lastNameKana === 'valid'}
                error={form.lastNameKana === 'invalid'}
                errorMessage="ひらがなで入力してください"
              />
            </StyledDiv>
          </div>
          <InputForm
            required={true}
            title="メールアドレス"
            help="半角英数字のみ"
            width={inputFormWidth}
            height={inputFormHeight}
            placeholder="tiramisu@bene-regalo.com"
            id="mailAddress"
            type="email"
            value={customer.mailAddress || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'mailAddress', 'mail');
            }}
            onKeyUp={(e) => {
              onKeyUpInputForm(e, 'mailAddress', 'textArea');
              onKeyUpMailAddress(e);
            }}
            check={form.mailAddress === 'valid'}
            error={form.mailAddress === 'invalid'}
            errorMessage="有効なメールアドレスを入力してください"
          />
          <div className={classNames(css.textAreaTitle)}>お問い合わせ内容</div>
          <textarea
            id="textArea"
            className={classNames(css.textArea)}
            value={customer.inquiryBody || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'inquiryBody', 'textArea');
            }}
          />
          <div className={classNames(css.checkBoxWrap)}>
            <img
              src={image[`check_box_${isButtonActive ? 'active' : 'passive'}.svg`]}
              className={classNames(css.checkBox)}
              onClick={() => {
                setIsButtonActive(!isButtonActive);
              }}
            />
            <Link
              style={{
                color: '#3EB370',
                marginTop: '12px',
                textDecoration: 'underline',
              }}
              to={'/policy'}
              rel="noopener noreferrer"
            >
              <span
                onClick={() => {
                  updateTermsAndPolicy('terms', true);
                }}
              >
                利用規約
              </span>
            </Link>
            <span>・</span>
            <Link
              style={{
                color: '#3EB370',
                marginTop: '12px',
                textDecoration: 'underline',
              }}
              to={'/policy'}
              rel="noopener noreferrer"
            >
              <span
                onClick={() => {
                  updateTermsAndPolicy('terms', false);
                }}
              >
                プライバシーポリシー
              </span>
            </Link>
            に同意する
          </div>
          <LinkButton
            width={inputFormWidth}
            height={linkButtonHeight}
            text="送信する"
            color="black"
            active={isValid && isButtonActive}
            loading={buttonIsLoading}
            onClick={() => {
              sendContact();
            }}
          />
          <StyledDiv
            css={`
              margin-bottom: 6vw !important;
            `}
          />
        </StyledDiv>
      </div>
    </>
  );
};
