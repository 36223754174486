import { getDocs, collection } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';

import { db } from '../../zz_general/utils/configs/firebase';

export const getAddLPProduct = async () => {
  return await getDocs(collection(db, 'LP', 'LP', 'items'));
};
export const getLpProduct = async () => {
  return await getDocs(collection(db, 'items'));
};