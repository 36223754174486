import { isProd } from '../snippets/env';

const url = isProd
  ? 'https://asia-northeast1-bene-reglo-lp.cloudfunctions.net/'
  : 'http://127.0.0.1:5001/bene-reglo-lp/asia-northeast1/';

// sendLarkLog
/**
 * [概要]
 ** 任意の文章をLarkに送信する
 * @param {string} group 送信先のグループ名
 * @param {string} text 送信するテキスト
 * @returns {Promise<void>} サーバーからのレスポンス
 *
 * [グループ名一覧]
 ** テスト用: 'test',
 ** quizエラー: 'quiz_error',
 ** quiz失敗: 'quiz_failure',
 ** quiz成功: 'quiz_achieve',
 ** LPエラー: 'lp_error',
 */
export const BRv2_lark_postMassage = async (group, message) => {
  const endpoint = url + 'BRv2_lark-postMassage';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        group: group,
        message: message,
      }),
    });
    const data = await response.json();
    // eslint-disable-next-line no-console
    console.log('Response:', data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
  }
};

/**
 * [abstract]
 ** Send an email to the user who made an inquiry and send a notification to Lark
 * @param {string} docId document ID of Firestore
 */
export const BRv2_inquiry_sendEmail = async (docId) => {
  const endpoint = url + 'BRv2_inquiry-sendEmail';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        docId: docId,
      }),
    });
    const data = await response.json();
    // eslint-disable-next-line no-console
    console.log('Response:', data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
  }
};

/**
 * [abstract]
 ** Send an email to the user who made a delivery reservation and send a notification to Lark
 * @param {string} docId document ID of Firestore
 */
export const BRv2_delivery_sendEmail = async (docId) => {
  const endpoint = url + 'BRv2_delivery-sendEmail';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        docId: docId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return { success: false, error: error.message };
  }
};

/**
 * [abstract]
 ** Send an email to the user who made a takeout reservation and send a notification to Lark
 * @param {string} docId document ID of Firestore
 */
export const BRv2_takeout_sendEmail = async (docId) => {
  const endpoint = url + 'BRv2_takeout-sendEmail';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        docId: docId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return { success: false, error: error.message };
  }
};

/**
 * [abstract]
 ** verify the tweet URL
 * @param {string} tweetUrl tweet URL
 */
export const BRv2_quiz_verifyTweet = async (tweetUrl) => {
  const endpoint = url + 'BRv2_quiz-verifyTweet';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tweetUrl: tweetUrl,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return { data: { valid: false, error: error } };
  }
};

/**
 * [abstract]
 ** Send a LINE message to the user
 * @param {string} lineId user's LINE
 * @param {string} messageText content of the message
 */
export const BRv2_line_sendMessage = async (lineId, messageText) => {
  const endpoint = url + 'BRv2_line-sendMessage';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lineId: lineId,
        messageText: messageText,
      }),
    });
    const res = await response.json();
    // eslint-disable-next-line no-console
    console.log('Response:', res);
    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
    return { success: false, result: error };
  }
};

/**
 * [abstract]
 ** Fetch LINE user data and validate token
 * @param {string} code Optional code to be included in the response
 * @param {string} redirectUri URL to redirect after LINE login
 */
export const BRv2_line_login = async (code, redirectUri) => {
  const endpoint = url + 'BRv2_line-login';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: code,
        redirectUri: redirectUri,
      }),
    });
    const res = await response.json();
    // eslint-disable-next-line no-console
    console.log('Response:', res);
    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
    return { success: false, error: error };
  }
};

/**
 * [abstract]
 ** Fetch a payment link for PayPay
 * @param {object} payPayPayload Payload to create payment link: json object including merchantPaymentId, amount, redirectUrl
 */
export const BRv2_paypay_createPaymentLink = async (payPayPayload) => {
  const endpoint = url + 'BRv2_paypay-createPaymentLink';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payPayPayload),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
    return { success: false, error: error };
  }
};

/**
 * [abstract]
 ** Fetch a payment link for PayPay
 * @param {object} payPayPayload Payload to create payment link: json object including merchantPaymentId, amount, redirectUrl
 */
export const BRv2_stripe_createPaymentIntent = async (stripePayload) => {
  const endpoint = url + 'BRv2_stripe-createPaymentIntent';
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(stripePayload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
    return { success: false, error: error };
  }
};
