import { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import css from '../../index.module.scss';
import managementCss from '../index.module.scss';
import { getDocs, collection, query } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';
import { useDropzone } from 'react-dropzone';
import { readString, useCSVDownloader } from 'react-papaparse';
import ExcelJS from 'exceljs';
import { sheetHeaders, sheetBody } from './fixture';
import dayjs from 'dayjs';

//受注管理
export const Order = (props) => {
  const [filterOrders, setFilterOrders] = useState([]);

  const OrderListFilter = (props) => {
    // MEMO: 受付日付
    const dateOfReceipt = props.order[1]?.orderDate?.toDate()
      ? dayjs(props.order[1].orderDate.toDate()).format('YYYY/MM/DD')
      : '';

    return (
      <div>
        <div className={classNames(managementCss.list_element)}>
          <div className={classNames(managementCss.customer_id)}>{props.order[0]}</div>
          <div className={classNames(managementCss.name)}>
            {props.order[1]?.customerInfo?.basicInfo?.lName || ''}
            {props.order[1]?.customerInfo?.basicInfo?.fName || ''}
          </div>
          <div className={classNames(managementCss.name)}>{dateOfReceipt}</div>
          {props.order[1]?.deliverySlipNumber ? (
            <div
              className={classNames(managementCss.name)}
              onClick={() => {
                props.setInCSV(props.order);
              }}
            >
              {props.order[1]?.deliverySlipNumber}
            </div>
          ) : (
            <div className={classNames(managementCss.name)}>{'No Data'}</div>
          )}
        </div>
      </div>
    );
  };

  //firesotore検索用state(検索条件)
  const [searchCriteria, setSearchCriteria] = useState({
    orderDate: '', //注文日時
    name: '', //名前
    member: '', //会員・非会員
    shippingDate: '', //発送日時
    mail: '', //メールアドレス
    LINE: '', //LINE連携・未連携
    deliveryDate: '', //配送日時
    repeat: '', //購入回数
    shipped: '', //発送・未発送
  });

  const updateSearchCriteria = (key, value) => {
    setSearchCriteria((prevState) => ({ ...prevState, [key]: value }));
  };

  //firebaseから注文情報を取得
  const handleClickOrders = () => {
    async function getOrders() {
      const q = query(collection(db, 'orders'));
      const querySnapshot = await getDocs(q);
      return querySnapshot;
    }
    const promise = getOrders();
    promise
      .then((response) => {
        const filterOrderList = [];
        response.forEach((doc) => {
          if (searchCriteria.shipped === '' || doc.data().deliverySlipNumber === 'not yet') {
            filterOrderList.push([<OrderListFilter order={[doc.id, doc.data()]} key={doc.id} setInCSV={setInCSV} />]);
          }
        });
        setFilterOrders(filterOrderList);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  //csvのインポート
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      // eslint-disable-next-line no-console
      reader.onabort = () => console.log('file reading was aborted');
      // eslint-disable-next-line no-console
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;

        readString(binaryStr, {
          worker: true,
          complete: (results) => {
            results.data.map((row) => {
              // eslint-disable-next-line no-console
              console.log(row);
            });
          },
        });
      };
      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  //現在日時取得関数 YYYYMMDDHHMMMのフォーマットで現在日時を取得、ファイル名のsuffixとして付ける
  const padZero = (num) => {
    return (num < 10 ? '0' : '') + num;
  };
  const getCurrentDatetime = () => {
    const now = new Date();
    return (
      '' +
      now.getFullYear() +
      padZero(now.getMonth() + 1) +
      padZero(now.getDate()) +
      padZero(now.getHours()) +
      padZero(now.getMinutes())
    );
  };

  //CSVダウンロード関数 呼び出されるときに、パラメータとしてファイル名prefix、ボタン名、JSONデータをもらう
  const CSVDownloader = (props) => {
    const { CSVDownloader, Type } = useCSVDownloader();
    const filename = props.filenameprefix + '_' + getCurrentDatetime();
    return (
      <CSVDownloader type={Type.Button} filename={filename} bom={true} config={{ delimiter: ',' }} data={props.data}>
        <div className={managementCss.batchCreation}>一括作成</div>
      </CSVDownloader>
    );
  };

  const [jsondata, setJsondata] = useState([]);
  const data = [];

  const setInCSV = (order) => {
    const isPresent = order[1].orderInfo.address === order[1].customerInfo.address;

    let deliveryDateStr = '指定なし';
    let shippingDateStr = '指定なし';
    let deliveryTime = '指定なし';

    if (order[1].orderInfo.items.deliveryDate !== '指定なし') {
      const date = order[1].orderInfo.items.deliveryDate;
      const dateList = date.replace(/年|月|日/g, ' ').split(' ');
      const shippingDate = new Date(dateList[0] + '/' + dateList[1] + '/' + dateList[2]);
      shippingDate.setDate(shippingDate.getDate() - 2);
      deliveryDateStr = dateList[0] + '/' + dateList[1] + '/' + dateList[2];
      shippingDateStr =
        shippingDate.getFullYear() + '/' + `${shippingDate.getMonth() + 1}` + '/' + shippingDate.getDate();
    }
    if (order[1].orderInfo.items.deliveryTime !== '指定なし') {
      const time = order[1].orderInfo.items.deliveryTime;
      const timeList = time.replace(/:| ~ /g, ' ').split(' ');
      deliveryTime = timeList[0].padStart(2, '0') + timeList[2].padStart(2, '0');
    }

    data.push({
      お客様管理番号: order[0],
      送り状種類: 0,
      クール区分: 1,
      伝票番号: '',
      出荷予定日: shippingDateStr,
      お届け予定日: deliveryDateStr,
      配達時間帯: deliveryTime,
      お届け先コード: '',
      お届け先電話番号: order[1].orderInfo.phoneNum,
      お届け先電話番号枝番: '',
      お届け先郵便番号: order[1].orderInfo.postalCode,
      お届け先住所: '東京都千代田区皇居外苑2',
      お届け先アパートマンション名: 'ティラミスビル22階',
      'お届け先会社・部門１': order[1].orderInfo.corporation,
      'お届け先会社・部門２': '',
      お届け先名: order[1].orderInfo.lName + order[1].orderInfo.fName,
      'お届け先名(ｶﾅ)': '',
      敬称: '',
      ご依頼主コード: '',
      ご依頼主電話番号: isPresent ? order[1].customerInfo.phoneNum : '042-808-7933',
      ご依頼主電話番号枝番: '',
      ご依頼主郵便番号: isPresent ? order[1].customerInfo.postalCode : '197-0804',
      ご依頼主住所: '東京都あきる野市秋川1-8',
      ご依頼主アパートマンション: 'あきる野ルピア1階',
      ご依頼主名: isPresent ? order[1].customerInfo.lName + order[1].customerInfo.fName : 'BENE REGALO',
      'ご依頼主名(ｶﾅ)': '',
      品名コード１: '',
      品名１: '●PT in Box × 1',
      品名コード２: '',
      品名２: '',
      荷扱い１: '',
      荷扱い２: '',
      記事: '',
      'ｺﾚｸﾄ代金引換額（税込)': '',
      内消費税額等: '',
      止置き: '',
      営業所コード: '',
      発行枚数: '',
      個数口表示フラグ: '',
      請求先顧客コード: '0428087933',
      請求先分類コード: '',
      運賃管理番号: '01',
      クロネコwebコレクトデータ登録: '',
      クロネコwebコレクト加盟店番号: '',
      クロネコwebコレクト申込受付番号１: '',
      クロネコwebコレクト申込受付番号２: '',
      クロネコwebコレクト申込受付番号３: '',
      お届け予定ｅメール利用区分: '',
      'お届け予定ｅメールe-mailアドレス': '',
      入力機種: '',
      お届け予定ｅメールメッセージ: '',
      お届け完了ｅメール利用区分: '',
      'お届け完了ｅメールe-mailアドレス': '',
      お届け完了ｅメールメッセージ: '',
      クロネコ収納代行利用区分: '',
      予備: '',
      '収納代行請求金額(税込)': '',
      収納代行内消費税額等: '',
      収納代行請求先郵便番号: '',
      収納代行請求先住所: '',
      '収納代行請求先住所（アパートマンション名）': '',
      '収納代行請求先会社・部門名１': '',
      '収納代行請求先会社・部門名２': '',
      '収納代行請求先名(漢字)': '',
      '収納代行請求先名(カナ)': '',
      '収納代行問合せ先名(漢字)': '',
      収納代行問合せ先郵便番号: '',
      収納代行問合せ先住所: '',
      '収納代行問合せ先住所（アパートマンション名）': '',
      収納代行問合せ先電話番号: '',
      収納代行管理番号: '',
      収納代行品名: '',
      収納代行備考: '',
      複数口くくりキー: '',
      検索キータイトル1: '',
      検索キー1: '',
      検索キータイトル2: '',
      検索キー2: '',
      検索キータイトル3: '',
      検索キー3: '',
      検索キータイトル4: '',
      検索キー4: '',
      // 入力時不要ここから
      検索キータイトル5: '',
      検索キー5: '',
      予備1: '',
      予備2: '',
      // 入力時不要ここまで
      投函予定メール利用区分: '',
      '投函予定メールe-mailアドレス': '',
      投函予定メールメッセージ: '',
      '投函完了メール（お届け先宛）利用区分': '',
      '投函完了メール（お届け先宛）e-mailアドレス': '',
      '投函完了メール（お届け先宛）メールメッセージ': '',
      '投函完了メール（ご依頼主宛）利用区分': '',
      '投函完了メール（ご依頼主宛）e-mailアドレス': '',
      '投函完了メール（ご依頼主宛）メールメッセージ': '',
    });
    setJsondata(data);
  };

  useEffect(() => {
    if (props.tab === 'order') {
      handleClickOrders();
    }
  }, [props.tab]);

  const xlsExport = async (e) => {
    // MEMO: https://dev.classmethod.jp/articles/implement-data-export-in-excel-or-csv-format-in-react-app/
    e.preventDefault();
    const now = dayjs().format('YYYY-MM-DD_HH-mm');
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');
    worksheet.columns = sheetHeaders;
    worksheet.addRows(sheetBody(filterOrders));

    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${now}_YamatoExportData.xlsx`;
    a.click();
    a.remove();
  };

  return (
    <div className={classNames(managementCss.managementOrder, props.tab === 'order' ? '' : css.displayNone)}>
      <div className={managementCss.titleAndLinks}>
        <div className={managementCss.iconAndTitle}>
          <img src={`${process.env.PUBLIC_URL}/CartLogo.svg`} alt="" className={managementCss.icon} />
          <div className={managementCss.title}>受注管理</div>
        </div>
        <div className={managementCss.links}>
          <a
            href={
              'https://bmypage.kuronekoyamato.co.jp/bmypage/servlet/jp.co.kuronekoyamato.wur.hmp.servlet.user.HMPLGI0010JspServlet'
            }
            target={'_blank'}
            rel="noreferrer"
            className={managementCss.ybm}
          >
            ヤマトビジネスメンバーズ
          </a>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className={managementCss.register}>Drop the files here</div>
            ) : (
              <div className={managementCss.register}>出荷伝票登録</div>
            )}
          </div>
          <CSVDownloader data={jsondata} filenameprefix="export" />
          <div className={managementCss.newCreation}>新規作成</div>
        </div>
      </div>
      <div className={managementCss.search}>
        <div className={managementCss.searchTitle}>検索条件</div>
        <div className={managementCss.searchCriterias}>
          <div className={managementCss.searchCriteriasFlex}>
            <div className={managementCss.searchCriteria}>
              注文日時
              <br />
              <input placeholder="2023-02-01 00:00:00 - 2023-02-01 00:00:00" className={managementCss.dateTimeInput} />
            </div>
            <div className={managementCss.searchCriteria}>
              名前
              <br />
              <input />
            </div>
            <div className={managementCss.searchCriteria}>
              会員
              <br />
              <div>会員</div>
              <div>非会員</div>
            </div>
          </div>
          <div className={managementCss.searchCriteriasFlex}>
            <div className={managementCss.searchCriteria}>
              発送日時
              <br />
              <input placeholder="2023-02-01 00:00:00 - 2023-02-01 00:00:00" className={managementCss.dateTimeInput} />
            </div>
            <div className={managementCss.searchCriteria}>
              メールアドレス
              <br />
              <input />
            </div>
            <div className={managementCss.searchCriteria}>LINE連携</div>
          </div>
          <div className={managementCss.searchCriteriasFlex}>
            <div className={managementCss.searchCriteria}>
              配送日時
              <br />
              <input placeholder="2023-02-01 00:00:00 - 2023-02-01 00:00:00" className={managementCss.dateTimeInput} />
            </div>
            <div className={managementCss.searchCriteria}>
              注文回数
              <br />
              <input />
            </div>
            <div className={managementCss.searchCriteria}>
              発送
              <br />
              <div
                className={searchCriteria.shipped === '' ? '' : css.displayNone}
                onClick={() => {
                  updateSearchCriteria('shipped', 'not yet');
                }}
              >
                指定なし
              </div>
              <div
                className={searchCriteria.shipped === 'not yet' ? '' : css.displayNone}
                onClick={() => {
                  updateSearchCriteria('shipped', '');
                }}
              >
                未発送
              </div>
            </div>
          </div>
        </div>
        <div className={managementCss.searchCriterias}>
          <div className={managementCss.searchCriteriasFlex}>
            <div className={managementCss.searchCriteria}>
              検索件数
              <input />
            </div>
            <div className={managementCss.deleteAll}>クリア</div>
            <div
              className={managementCss.performSearch}
              onClick={() => {
                handleClickOrders();
              }}
            >
              この条件で検索する
            </div>
          </div>
        </div>
      </div>
      <button className={managementCss.xls_export_button} onClick={(e) => xlsExport(e)}>
        ヤマトエクセル伝票書き出し
      </button>

      <div className={classNames(managementCss.customer_list)}>
        <div className={classNames(managementCss.list_element)}>
          <div className={classNames(managementCss.customer_id)}>ID</div>
          <div className={classNames(managementCss.name)}>名前</div>
          <div className={classNames(managementCss.name)}>受付日付</div>
          <div className={classNames(managementCss.name)}>???</div>
        </div>

        {filterOrders}
      </div>
    </div>
  );
};
