import { useEffect, useState } from 'react';
import classNames from 'classnames';
import css from '../../index.module.scss';
import managementCss from '../index.module.scss';
import { getDocs, collection, query } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';

export const Customer = (props) => {
  const [customers, setCustomers] = useState([]);

  const CustomerList = (props) => {
    return (
      <div>
        <div className={classNames(managementCss.list_element)}>
          <div className={classNames(managementCss.customer_id)}>{props.customer[0]}</div>
          <div className={classNames(managementCss.name)}>{props.customer[1].name}</div>
        </div>
      </div>
    );
  };

  //firebaseから顧客情報を取得
  const handleClickCustomers = () => {
    async function getCustomers() {
      const q = query(collection(db, 'customers'));
      const querySnapshot = await getDocs(q);
      return querySnapshot;
    }
    const promise = getCustomers();
    promise
      .then((response) => {
        const customerList = [];
        response.forEach((doc) => {
          customerList.push([<CustomerList customer={[doc.id, doc.data()]} key={doc.id} />]);
        });
        setCustomers(customerList);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (props.tab === 'customer') {
      handleClickCustomers();
    }
  }, [props.tab]);
  return (
    <div className={classNames(managementCss.customer, props.tab === 'customer' ? '' : css.displayNone)}>
      顧客管理
      <div className={classNames(managementCss.customer_list)}>
        <div className={classNames(managementCss.list_element)}>
          <div className={classNames(managementCss.customer_id)}>ID</div>
          <div className={classNames(managementCss.name)}>名前</div>
        </div>
        {customers}
      </div>
    </div>
  );
};
